import {SetpsBody} from '../Steps.styled'
import {forwardRef, useEffect, useState, useImperativeHandle, useRef} from 'react'
import {
  EnrollmentOptions,
  EventOptions,
  getPromotionRequestObj,
  LIMIT_TYPES,
  limitOptions,
  PromotionApiData,
  triggerActivitiesOptions,
  updateTriggerRuleData,
  EnrollmentTypeOptions,
  SpendTypeOptions,
  // untilPromotionEndDateOptions,
  getpointsThreshold,
} from '../../utils'
import ProductService from 'app/services/ProductService'
import CategoryRow from './CategoryRow'
import {
  AddMoreAttributesWrapper,
  CategoryRowWrapper,
  EventPointWrapper,
  EventWrapper,
  PromotionTypeDetailsBody,
  PromotionTypeDetailsWrapper,
  TriggerLimitsSection,
  TriggerLimitsWrapper,
} from './Trigger.styled'
import Button from 'lib/components/Button'
import {toast} from 'react-hot-toast'
import {FormTypes} from 'lib/components/Form/Form'
import Typography from 'lib/components/Typography'
import Form from 'lib/components/Form'
import LoadingPanel from 'lib/components/LoadingPanel'
import Colors from 'styles/Colors'
import TriggerLimit, {TriggerLimitRefType} from './TriggerLimit'
import {CheckboxValueType} from 'antd/es/checkbox/Group'
import {VerticalRadioWrapper} from 'lib/components/Form/Radio/Radio.Styled'
import Tabs from 'app/common/components/Tabs'
import {cloneDeep} from 'lodash'
import Appconfig from 'app/common/helpers/AppConfig'
import RewardService from 'app/services/RewardService'
import {MarginBottomWrapper, MarginRightWrapper} from 'app/common/components/Styled/common.styled'
import IconAdd from 'app/common/icons/IconAdd'
import PromotionService from 'app/services/PromotionService'
import MultiCounterRow from './MultiCounterRow'
import PointService from 'app/services/PointService'
import {Col, Row} from 'antd'
import ToggleButton from 'lib/components/ToggleButton'
import dayjs from 'dayjs'

export interface TriggerRefType {
  validateTrigger(): boolean
  getState(): PromotionsTypes.TriggerRuleData | null
}

const Trigger = forwardRef<TriggerRefType>((props, forwardedRef) => {
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }

  const triggerLimitRef = useRef<TriggerLimitRefType>(null)
  const [isCategoryLoading, setCategoryLoading] = useState(true)
  const [categoryList, setCategoryList] = useState<ProductTypes.ProductMasterCategoryResponse[] | null>([])
  const getPromotionResponse = getPromotionRequestObj
  const latestTriggerRuleData = getPromotionResponse.promotionRuleData.triggerRuleData
  const [triggerRuleData, setTriggerRuleData] = useState<PromotionsTypes.TriggerRuleData | null>(latestTriggerRuleData)
  const [errorIndex, setErrorErrorIndexs] = useState<number[]>([])
  const promotionType = getPromotionRequestObj.promotionType.toUpperCase()
  const getActiveTabId = (): number => {
    switch (promotionType) {
      case 'TRANSACTION':
        return 0
      case 'ACTIVITY':
        return 1
      case 'ENROLLMENT':
        return 2
      case 'EVENT':
        return 3
      case 'MULTICOUNTER':
        return 4
      case 'SPEND':
        return 5
    }
    return 0
  }
  const latestEnrollmentRuleData = triggerRuleData?.enrollmentRuleData
  const regularRuleEnrollment = latestEnrollmentRuleData?.regularRuleEnrollment
  const earnPointDate = latestEnrollmentRuleData?.condititonalRuleEnrollment?.earnPointRuleEnrollment
  const promotionRuleEnrollment = latestEnrollmentRuleData?.condititonalRuleEnrollment?.promotionRuleEnrollment
  const initialEarnPointState: PromotionsTypes.EarnPointRuleEnrollment = {
    pointTypeCode: '',
    points: 0,
    days: 0,
  }
  const initialPromotionCompleteState: PromotionsTypes.PromotionRuleEnrollment = {
    promotionName: '',
    promotionCode: '',
    totalDays: 0,
  }
  let enrollmentTypeSelected = ''
  if (!regularRuleEnrollment?.awardOnEnrollment && earnPointDate) {
    enrollmentTypeSelected = 'earningPoints'
  }
  if (!regularRuleEnrollment?.awardOnEnrollment && promotionRuleEnrollment) {
    enrollmentTypeSelected = 'promotionCompletion'
  }

  const [activePromotionType, setActivePromotionType] = useState<string>(getPromotionRequestObj.promotionType.toUpperCase())
  const [selectedActivity, setSelectedActivity] = useState<string>(
    latestTriggerRuleData ? latestTriggerRuleData.activityRuleData?.activityCode || '' : ''
  )
  const [limitInfo, setLimitInfo] = useState<PromotionsTypes.LimitData | null>(latestTriggerRuleData ? latestTriggerRuleData.limitData : null)
  const [selectedEnrollment, setSelectedEnrollment] = useState<string>(enrollmentTypeSelected)
  const [selectedEvent, setSelectedEvent] = useState<string>(latestTriggerRuleData ? latestTriggerRuleData.eventRuleData?.eventCode || '' : '')
  const [pointTypeCode, setPointTypeCode] = useState<string>(latestTriggerRuleData ? latestTriggerRuleData.eventRuleData?.pointTypeCode || '' : '')
  const [eventValue, setEventValue] = useState<number>(getpointsThreshold())
  const [isResetLimit, setIsResetLimit] = useState<boolean>(false)
  const [activeActivity, setActiveActivity] = useState<string>('')
  const [activeTabId, setActiveTabId] = useState(getActiveTabId())
  const [rewardsDataOptions, setRewardsDataOptions] = useState<FormTypes.Options[]>([])
  const [pointTypeOptions, setPointTypeOptions] = useState<FormTypes.Options[]>([])
  const [isPointTypeLoading, setIsPointtypeLoading] = useState(false)
  const [orgnlPromotionsDataOptions, setOrgnlPromotionsDataOptions] = useState<FormTypes.Options[]>([])
  const [promotionsDataOptions, setPromotionsDataOptions] = useState<FormTypes.Options[]>([])
  const [isFetchingPromotions, setIsFetchingPromotions] = useState<boolean>(false)
  const [loadingRewards, setLoadingRewards] = useState<boolean>(false)
  // set to true for Regular Enrollment and false for Conditional Enrollment
  const [enrollmentType, setEnrollmentType] = useState<boolean>(Boolean(regularRuleEnrollment?.awardOnEnrollment))
  // set using trigger data
  // const [pointsMilestone, setPointsMilestone] = useState<number>()
  const [daysAfterEnroll, setDaysAfterEnroll] = useState<number>(
    getPromotionRequestObj.promotionRuleData?.triggerRuleData?.spendRuleData?.withiEnrollmentDays ?? 0
  )
  // const [promotion, setPromotion] = useState<string>('')
  const [earnPointState, setEarnPointState] = useState<PromotionsTypes.EarnPointRuleEnrollment>(earnPointDate || initialEarnPointState)
  const [promotionCompleteState, setPromotionCompleteState] = useState<PromotionsTypes.PromotionRuleEnrollment>(
    promotionRuleEnrollment || initialPromotionCompleteState
  )

  // set to true for spend with limited period and false for Spend & earn points
  const [spendType, setSpendType] = useState<boolean>(
    getPromotionRequestObj.promotionRuleData?.triggerRuleData?.spendRuleData?.spendType === 'LIMITEDPERIOD'
  )
  // set using trigger data
  const [spendValue, setSpendValue] = useState<number>(getPromotionRequestObj.promotionRuleData?.triggerRuleData?.spendRuleData?.spendValue ?? 0)
  const initailTimePeriod = triggerRuleData?.spendRuleData?.timePeriod
    ? [triggerRuleData.spendRuleData.timePeriod.from, triggerRuleData.spendRuleData.timePeriod.to]
    : []
  const [timePeriod, setTimePeriod] = useState<string[]>(initailTimePeriod)
  //const [isChecked, setIsChecked] = useState('')
  // const [isUntilPromoEndDate, setIsUntilPromoEndDate] = useState('')

  // const promotionOptions: FormTypes.Options[] = []

  const getActivityRewards = (): string[] => {
    if (
      activePromotionType === 'ACTIVITY' &&
      latestTriggerRuleData &&
      latestTriggerRuleData.activityRuleData &&
      latestTriggerRuleData.activityRuleData?.activityCode === 'Redemption' &&
      latestTriggerRuleData.activityRuleData.reward
    ) {
      return latestTriggerRuleData.activityRuleData.reward.map((item) => item.rewardCode)
    }
    return []
  }
  let availableSelRewards = getActivityRewards()
  const [selRewards, setSelRewards] = useState<string[]>([])
  const [promoCounter, setPromoCounter] = useState<PromotionsTypes.PromoCounterType[]>(
    latestTriggerRuleData && latestTriggerRuleData.multiCounterData
      ? latestTriggerRuleData.multiCounterData.promoCounter
      : [
          {
            promotionName: '',
            promotionCode: '',
            counter: 1,
          },
        ]
  )

  const getState = (): PromotionsTypes.TriggerRuleData | null => {
    return triggerRuleData
  }

  useImperativeHandle(forwardedRef, () => ({validateTrigger, getState}))

  const validateTrigger = () => {
    let isTriggerLimitValid = true
    const limitData = triggerRuleData?.limitData
    if (limitData && limitData.campaignLimit && !limitData.campaignLimit.limitQuantity) {
      toast.error('Please select quantity')
      isTriggerLimitValid = false
      return false
    } else if (limitData && limitData.userLimit && !limitData.userLimit.limitQuantity) {
      isTriggerLimitValid = false
      toast.error('Please select quantity')
      return false
    }
    if (limitData && limitData.campaignLimit && !limitData.campaignLimit?.limitFrequency) {
      toast.error('Please select Frequency')
      isTriggerLimitValid = false
      return false
    } else if (limitData && limitData.userLimit && !limitData.userLimit.limitFrequency) {
      isTriggerLimitValid = false
      toast.error('Please select Frequency')
      return false
    }
    if (activePromotionType.toUpperCase() === 'TRANSACTION') {
      if (!triggerRuleData) {
        return true && isTriggerLimitValid
      }
      if (!triggerRuleData.triggerData) {
        return true && isTriggerLimitValid
      }
      if (triggerRuleData.triggerData.length === 0) {
        return true && isTriggerLimitValid
      }

      if (triggerRuleData.triggerData.length > 0) {
        const errorIndexes: number[] = []
        triggerRuleData.triggerData.map((item, index: number) => {
          if (!item.productCategory) {
            errorIndexes.push(index)
            return null && isTriggerLimitValid
          }
          if (item.values.length === 0) {
            errorIndexes.push(index)
            return null && isTriggerLimitValid
          }
          return null && isTriggerLimitValid
        })
        if (errorIndexes.length > 0) {
          setErrorErrorIndexs(errorIndexes)
          toast.error('Please add the valid Category and Category Value')
          return false && isTriggerLimitValid
        } else {
          setErrorErrorIndexs([])
          return true && isTriggerLimitValid
        }
      }
    } else if (activePromotionType === 'ACTIVITY') {
      if (selectedActivity && selectedActivity === 'Redemption') {
        if (triggerRuleData?.activityRuleData?.reward?.length) {
          return true && isTriggerLimitValid
        } else {
          toast.error('Please select atleast one reward for Redemption.')
          return false
        }
      } else if (selectedActivity && selectedActivity !== 'Redemption') {
        return true && isTriggerLimitValid
      } else {
        toast.error('Please add atleast one activity')
        return false && isTriggerLimitValid
      }
    } else if (activePromotionType === 'ENROLLMENT') {
      if (
        triggerRuleData?.enrollmentRuleData?.regularRuleEnrollment == null &&
        triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment == null
      ) {
        toast.error('Please select atleast one Enrollment')
        return false && isTriggerLimitValid
      }
      if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment != null) {
        console.log(
          'triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment',
          triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment
        )
        if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment != null) {
          if (
            triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment.pointTypeCode === '' ||
            triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment.pointTypeCode === null
          ) {
            toast.error("PointType can't be null or empty!")
            return false && isTriggerLimitValid
          }
          if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment.points <= 0) {
            toast.error('Points must be in between 0 and 10000.')
            return false && isTriggerLimitValid
          }
          if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.earnPointRuleEnrollment.days <= 0) {
            toast.error('Days must be greater than 0.')
            return false && isTriggerLimitValid
          }
        }
        if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.promotionRuleEnrollment != null) {
          if (
            triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.promotionRuleEnrollment.promotionName === '' ||
            triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.promotionRuleEnrollment.promotionName == null
          ) {
            toast.error('Select one promotion for the given list!')
            return false && isTriggerLimitValid
          }

          if (triggerRuleData?.enrollmentRuleData?.condititonalRuleEnrollment.promotionRuleEnrollment.totalDays <= 0) {
            toast.error('Days must be greater than 0.')
            return false && isTriggerLimitValid
          }
        }
      }

      return true && isTriggerLimitValid
    } else if (activePromotionType === 'EVENT') {
      if (!selectedEvent) {
        toast.error('Please add atleast one Event')
        return false && isTriggerLimitValid
      } else if (selectedEvent === 'Point') {
        if (pointTypeCode === '') {
          toast.error('Please select a point type')
          return false && isTriggerLimitValid
        }
        if (eventValue === 0) {
          toast.error('Please add threshold value.')
          return false && isTriggerLimitValid
        }

        if (eventValue > 10000000) {
          toast.error('Point thresholdValue should be less than equal to 10000000.')
          return false && isTriggerLimitValid
        }
      } else if (selectedEvent === 'membershipLifeTimeValue') {
        if (eventValue === 0) {
          toast.error('Please add threshold value.')
          return false && isTriggerLimitValid
        }
        if (eventValue > 10000000) {
          toast.error('Point thresholdValue should be less than equal to 10000000.')
          return false && isTriggerLimitValid
        }
      }
      return true && isTriggerLimitValid
    } else if (activePromotionType === 'MULTICOUNTER') {
      if (triggerRuleData?.multiCounterData?.promoCounter) {
        if (triggerRuleData.multiCounterData.promoCounter.some((item) => item.promotionName === '')) {
          toast.error('Please select Promotion from the list.')
          return false
        } else {
          return true
        }
      } else {
        toast.error('Please select Promotion from the list.')
        return false
      }
    } else if (activePromotionType === 'SPEND') {
      if (spendValue <= 0) {
        toast.error('Spend value should be greater than 0.')
        return false
      }
      if (spendType && timePeriod.length === 0) {
        toast.error('Please select Time Period .')
        return false
      }
      if (!spendType && daysAfterEnroll <= 0) {
        toast.error('Days After Enrollment  should be greater than zero.')
        return false
      }
      return true
    }
    return false && isTriggerLimitValid
  }

  const getCategoryList = () => {
    const {partnerCode} = getPromotionRequestObj
    setCategoryLoading(true)
    if (partnerCode) {
      ProductService.getProductCategory({PartnerCode: partnerCode})
        .then((res) => {
          const data = res.data.data
          setCategoryList(data)
          PromotionApiData.productCategory = data
        })
        .finally(() => {
          setCategoryLoading(false)
        })
    }
  }

  const handleAddAttr = () => {
    const prevTriggerData = triggerRuleData && triggerRuleData.triggerData ? [...triggerRuleData.triggerData] : []
    const updatedLimitData = triggerRuleData && triggerRuleData.limitData ? {...triggerRuleData.limitData} : null
    const newTriggerData: PromotionsTypes.TriggerData = {
      action: 'INCLUDE',
      productCategory: null,
      values: [],
    }
    prevTriggerData.push(newTriggerData)
    setTriggerRuleData({
      triggerData: prevTriggerData,
      activityRuleData: null,
      enrollmentRuleData: null,
      eventRuleData: null,
      limitData: updatedLimitData,
      multiCounterData: null,
      spendRuleData: null,
    })
  }

  const getApprovedRewards = (params: RewardsTypes.RewardSearchParam): void => {
    const reqParam: RewardsTypes.RewardSearchParam = {
      ...params,
      isAll: true,
      voucherStatus: 'APPROVED',
      enabled: true,
    }
    setLoadingRewards(true)
    RewardService.search(reqParam)
      .then((res) => {
        const data = res.data
        const updatedData = data.data.map((item) => ({
          label: `${item.voucherTypeName}-${item.voucherTypeCode}`,
          value: item.voucherTypeCode,
        }))
        setRewardsDataOptions([{value: 'all', label: 'All'}, ...updatedData])
        if (updatedData.length === availableSelRewards.length) {
          availableSelRewards = ['all', ...availableSelRewards]
        }
        setSelRewards(availableSelRewards)
      })
      .finally(() => {
        setLoadingRewards(false)
      })
  }

  const getApprovedPromotions = (params: PromotionsTypes.PromotionSearchParam): void => {
    const reqParam: PromotionsTypes.PromotionSearchParam = {
      ...params,
      isAll: true,
      PromotionStatusCode: 'APPROVED' || 'LIVE',
    }
    setIsFetchingPromotions(true)
    PromotionService.search(reqParam)
      .then((res) => {
        const data = res.data
        setOrgnlPromotionsDataOptions(
          data.data
            .filter((pro) => pro.promotionType !== 'MULTICOUNTER')
            .map((item) => ({
              label: item.promotionName,
              value: item.promotionCode,
            }))
        )
        const uniquePromo = data.data
          .filter((pro) => pro.promotionType !== 'MULTICOUNTER')
          .filter((item) => promoCounter.findIndex((promo) => promo.promotionCode === item.promotionCode) === -1)
        setPromotionsDataOptions(
          uniquePromo.map((item) => ({
            label: item.promotionName,
            value: item.promotionCode,
          }))
        )
      })
      .finally(() => {
        setIsFetchingPromotions(false)
      })
  }

  const getPointTypes = () => {
    setIsPointtypeLoading(true)
    PointService.getPointTypeCodes({pointTypeCode: undefined})
      .then((res) => {
        const data = res.data.data
        const formattedOptions = data.map((item) => ({
          label: item.pointTypeName,
          value: item.pointTypeCode,
        }))
        setPointTypeOptions(formattedOptions)
      })
      .finally(() => {
        setIsPointtypeLoading(false)
      })
  }

  useEffect(() => {
    if (!PromotionApiData.productCategory) {
      getCategoryList()
    } else {
      setCategoryList(PromotionApiData.productCategory)
      setCategoryLoading(false)
    }
    if (promotionType === 'ACTIVITY') {
      getApprovedRewards(initialPaginationObj)
    }

    if (promotionType === 'MULTICOUNTER') {
      getApprovedPromotions(initialPaginationObj)
    }
    if (promotionType === 'EVENT') {
      getPointTypes()
    }
    if (promotionType === 'ENROLLMENT') {
      // setSelectedEnrollment('awardOnEnrollment')
      getPointTypes()
      getApprovedPromotions(initialPaginationObj)
      // handleEnrollmentChange('awardOnEnrollment')
    }
  }, [])

  const categoryOptions = (): FormTypes.Options[] => {
    let options: FormTypes.Options[] = []
    if (categoryList && categoryList.length > 0) {
      options = categoryList?.map((item): FormTypes.Options => ({label: item.categoryName, value: item.categoryCode}))
    }
    return options
  }

  const handleDeleteRow = (rowIndex: number) => {
    const prevTriggerData = triggerRuleData && triggerRuleData.triggerData ? [...triggerRuleData.triggerData] : []
    if (prevTriggerData.length > 0) {
      prevTriggerData.splice(rowIndex, 1)
    }
    setTriggerRuleData({
      triggerData: prevTriggerData,
      activityRuleData: null,
      enrollmentRuleData: null,
      eventRuleData: null,
      limitData: null,
      multiCounterData: null,
      spendRuleData: null,
    })
  }
  // useEffect(() => {
  //   // setEarnPointState(initialEarnPointState)
  //   // setPromotionCompleteState(initialPromotionCompleteState)
  //   handleEnrollmentChange(selectedEnrollment)
  // }, [selectedEnrollment])

  const handleChangePromotionType = (type: string) => {
    if (type.toUpperCase() === activePromotionType) return
    getPromotionRequestObj.promotionType = type.toUpperCase()
    setActivePromotionType(type.toUpperCase())
    setTriggerRuleData({
      triggerData: [],
      activityRuleData: null,
      enrollmentRuleData: null,
      eventRuleData: null,
      limitData: null,
      multiCounterData: null,
      spendRuleData: null,
    })
    updateTriggerRuleData({
      triggerData: [],
      activityRuleData: null,
      enrollmentRuleData: null,
      eventRuleData: null,
      limitData: null,
      multiCounterData: null,
      spendRuleData: null,
    })
    setSelectedActivity('')
    setSelectedEnrollment('')
    setSelectedEvent('')
    setLimitInfo(null)
    setIsResetLimit(true)
    setSelRewards([])
    // setPointsMilestone(0)
    setDaysAfterEnroll(0)
    setTimePeriod([])
    // setIsChecked('')
    // setPromotion('')
    setSpendValue(0)
    setSpendType(true)
    setEnrollmentType(true)
    setPromoCounter([
      {
        promotionName: '',
        promotionCode: '',
        counter: 1,
      },
    ])
    if (type.toUpperCase() !== 'TRANSACTION' && getPromotionResponse.promotionRuleData.incentiveRuleData) {
      const incentiveData = getPromotionResponse.promotionRuleData.incentiveRuleData.incentiveData
      if (incentiveData.length && incentiveData[0].action === 'MULTIPLIER') {
        getPromotionResponse.promotionStatusCode = 'INCOMPLETE'
        getPromotionResponse.promotionRuleData.incentiveRuleData = null
      }
    }
    if (type.toUpperCase() === 'ACTIVITY') {
      getApprovedRewards(initialPaginationObj)
    }
    if (type.toUpperCase() === 'MULTICOUNTER') {
      getApprovedPromotions(initialPaginationObj)
    }
    if (type.toUpperCase() === 'EVENT') {
      getPointTypes()
    }
    if (type.toUpperCase() === 'ENROLLMENT') {
      setSelectedEnrollment('awardOnEnrollment')
      getPointTypes()
      getApprovedPromotions(initialPaginationObj)
      handleEnrollmentChange('awardOnEnrollment')
    }
  }

  const handleActivityChange = (value: string) => {
    const selActivityOption = triggerActivitiesOptions.find((item) => item.value === value)
    if (selActivityOption && triggerRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        activityRuleData: {
          activityName: selActivityOption.label,
          activityCode: selActivityOption.value,
          reward: selActivityOption.value === 'Redemption' ? [] : null,
        },
      })
    }
    setSelRewards([])
    setActiveActivity('')
    setSelectedActivity(value)
  }

  const handleShowActivityDetails = (value: string) => {
    setActiveActivity(value)
  }

  const handleEventChange = (value: string) => {
    const selEventOption = EventOptions.find((item) => item.value === value)
    const updatedTriggerRuleData = getPromotionRequestObj.promotionRuleData.triggerRuleData
    if (selEventOption && updatedTriggerRuleData) {
      setTriggerRuleData({
        ...updatedTriggerRuleData,
        eventRuleData: {
          eventName: selEventOption.label,
          eventCode: selEventOption.value,
          eventValue: 0,
          pointTypeCode: '',
        },
      })
    }
    setSelectedEvent(value)
    setEventValue(0)
    setPointTypeCode('')
  }

  const setTriggerLimits = (values: CheckboxValueType[], type: string) => {
    let updatedLimits = limitInfo
    if (updatedLimits) {
      if (!values.length) {
        updatedLimits = {
          ...updatedLimits,
          [type]: null,
        }
      } else {
        updatedLimits = {
          ...updatedLimits,
          [type]: {
            limitQuantity: 0,
            limitFrequency: '',
          },
        }
      }
      setLimitInfo(updatedLimits)
      if (triggerRuleData) {
        setTriggerRuleData({
          ...triggerRuleData,
          limitData: updatedLimits,
        })
      }
    } else {
      const limitsData = {
        userLimit:
          type === 'userLimit'
            ? {
                limitQuantity: 0,
                limitFrequency: '',
              }
            : null,
        campaignLimit:
          type === 'campaignLimit'
            ? {
                limitQuantity: 0,
                limitFrequency: '',
              }
            : null,
      }
      setLimitInfo(limitsData)
      if (triggerRuleData) {
        setTriggerRuleData({...triggerRuleData, limitData: limitsData})
      } else {
        setTriggerRuleData({
          triggerData: [],
          activityRuleData: null,
          enrollmentRuleData: null,
          eventRuleData: null,
          limitData: limitsData,
          multiCounterData: null,
          spendRuleData: null,
        })
      }
    }
  }

  const setTriggerLimitsData = (value: FormTypes.ValueType | null, type: string, inputType: string) => {
    let updatedLimits = limitInfo
    if (updatedLimits) {
      const updatedLimitData = {...updatedLimits, [type]: {...updatedLimits[type as keyof typeof LIMIT_TYPES], [inputType]: value}}
      updatedLimits = updatedLimitData
    }
    if (triggerRuleData) {
      setTriggerRuleData({...triggerRuleData, limitData: updatedLimits})
    }
    setLimitInfo(updatedLimits)
  }

  const handleCategoryChange = (data: PromotionsTypes.ProductCategoryData, rowIndex: number): void => {
    const updatedTriggerRuleData = cloneDeep(triggerRuleData)
    if (updatedTriggerRuleData?.triggerData) {
      updatedTriggerRuleData.triggerData[rowIndex].productCategory = data
    }
    setTriggerRuleData(updatedTriggerRuleData)
  }

  const handleCategoryValueChange = (values: string[], rowIndex: number): void => {
    const updatedTriggerRuleData = cloneDeep(triggerRuleData)
    if (updatedTriggerRuleData?.triggerData) {
      updatedTriggerRuleData.triggerData[rowIndex].values = values
    }
    setTriggerRuleData(updatedTriggerRuleData)
  }

  const handleCategoryActionChange = (value: string, rowIndex: number): void => {
    const updatedTriggerRuleData = cloneDeep(triggerRuleData)
    if (updatedTriggerRuleData?.triggerData) {
      updatedTriggerRuleData.triggerData[rowIndex].action = value as PromotionsTypes.TriggerDataAction
    }
    setTriggerRuleData(updatedTriggerRuleData)
  }

  const handleSelectRewards = (value: string[]): void => {
    let activityRewards: PromotionsTypes.ActivityRewardType[] = []
    let updatedValues = [...value]
    let actualValues = updatedValues.filter((item) => item !== 'all')
    if (value.includes('all') && value[value.length - 1] === 'all') {
      updatedValues = rewardsDataOptions.map((item) => item.value as string)
    } else if (value.includes('all') && value[0] === 'all') {
      updatedValues = actualValues
    } else if (!value.length || (selRewards.includes('all') && value.length === rewardsDataOptions.length - 1)) {
      updatedValues = []
    } else if (!value.includes('all') && value.length === rewardsDataOptions.length - 1) {
      updatedValues = ['all', ...updatedValues]
    }
    activityRewards = rewardsDataOptions
      .filter((item) => updatedValues.includes(item.value as string))
      .map((reward) => ({
        rewardName: reward.label?.toString().substring(0, reward.label?.toString().lastIndexOf('-')) as string,
        rewardCode: reward.value as string,
      }))
    const actualRewards = activityRewards.filter((item) => item.rewardCode !== 'all')
    if (triggerRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        activityRuleData: {
          activityCode: 'Redemption',
          activityName: 'Redemption',
          reward: actualRewards,
        },
      })
    }
    setSelRewards(updatedValues)
  }

  const handleOnTabChange = (tabId: number) => {
    setActiveTabId(tabId)
    switch (tabId) {
      case 1:
        handleChangePromotionType('ACTIVITY')
        break
      case 2:
        handleChangePromotionType('ENROLLMENT')
        break
      case 3:
        handleChangePromotionType('EVENT')
        break
      case 4:
        handleChangePromotionType('MULTICOUNTER')
        break
      case 5:
        handleChangePromotionType('SPEND')
        break
      default:
        handleChangePromotionType('TRANSACTION')
    }
  }

  const handleAddRow = () => {
    const updatedPromoCounter = [
      ...promoCounter,
      {
        promotionName: '',
        promotionCode: '',
        counter: 1,
      },
    ]
    setPromoCounter(updatedPromoCounter)
    if (triggerRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        multiCounterData: {
          promoCounter: updatedPromoCounter,
        },
      })
    }
  }

  const handleUpdateMultiCounterRow = (row: PromotionsTypes.PromoCounterType, rowIndex: number): void => {
    const updatedPromoCounter = cloneDeep(promoCounter)
    updatedPromoCounter[rowIndex] = row
    setPromoCounter(updatedPromoCounter)
    if (triggerRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        multiCounterData: {
          promoCounter: updatedPromoCounter,
        },
      })
    }
    const uniquePromo = orgnlPromotionsDataOptions.filter(
      (item) => updatedPromoCounter.findIndex((promo) => promo.promotionCode === item.value) === -1
    )
    setPromotionsDataOptions(
      uniquePromo.map((item) => ({
        label: item.label,
        value: item.value,
      }))
    )
  }

  const handleDeleteMultiCounterRow = (rowIndex: number): void => {
    const updatedPromoCounter = cloneDeep(promoCounter)
    updatedPromoCounter.splice(rowIndex, 1)
    setPromoCounter(updatedPromoCounter)
    if (triggerRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        multiCounterData: {
          promoCounter: updatedPromoCounter,
        },
      })
    }
    const uniquePromo = orgnlPromotionsDataOptions.filter(
      (item) => updatedPromoCounter.findIndex((promo) => promo.promotionCode === item.value) === -1
    )
    setPromotionsDataOptions(
      uniquePromo.map((item) => ({
        label: item.label,
        value: item.value,
      }))
    )
  }

  const handlePointTypeChange = (value: FormTypes.ValueType, key: string) => {
    setPointTypeCode(value as string)
    if (value && triggerRuleData && triggerRuleData.eventRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        eventRuleData: {
          ...triggerRuleData.eventRuleData,
          pointTypeCode: value as string,
        },
      })
    }
  }

  const handleThresholdChange = (value: FormTypes.ValueType, key: string) => {
    setEventValue(value as number)
    if (value && triggerRuleData && triggerRuleData.eventRuleData) {
      setTriggerRuleData({
        ...triggerRuleData,
        eventRuleData: {
          ...triggerRuleData.eventRuleData,
          eventValue: value as number,
        },
      })
    }
  }

  const handleSpendTypeChange = (value: boolean, id: string): void => {
    setSpendType(value)

    if (value) {
      setDaysAfterEnroll(0)
      setSpendValue(0)
    } else {
      setSpendValue(0)
      setTimePeriod([])
    }

    const spenRuleData: PromotionsTypes.SpendData = {
      spendType: value ? 'LIMITEDPERIOD' : 'ENROLLMENTPERIOD',
      spendValue: 0,
      timePeriod: null,
      withiEnrollmentDays: 0,
    }
    if (triggerRuleData) {
      setTriggerRuleData({...triggerRuleData, spendRuleData: spenRuleData})
    }
  }

  const handleEnrollmentChange = (value: string) => {
    if (value === 'awardOnEnrollment') {
      setTriggerRuleData({
        triggerData: [],
        activityRuleData: null,
        eventRuleData: null,
        limitData: null,
        multiCounterData: null,
        spendRuleData: null,
        enrollmentRuleData: {
          regularRuleEnrollment: {
            awardOnEnrollment: value ? true : false,
          },
          condititonalRuleEnrollment: null,
        },
      })
    } else if (value === 'earningPoints') {
      setTriggerRuleData({
        triggerData: [],
        activityRuleData: null,
        eventRuleData: null,
        limitData: null,
        multiCounterData: null,
        spendRuleData: null,
        enrollmentRuleData: {
          regularRuleEnrollment: null,
          condititonalRuleEnrollment: {
            earnPointRuleEnrollment: earnPointState,
            promotionRuleEnrollment: null,
          },
        },
      })
    } else if (value === 'promotionCompletion') {
      setTriggerRuleData({
        triggerData: [],
        activityRuleData: null,
        eventRuleData: null,
        limitData: null,
        multiCounterData: null,
        spendRuleData: null,
        enrollmentRuleData: {
          regularRuleEnrollment: null,
          condititonalRuleEnrollment: {
            earnPointRuleEnrollment: null,
            promotionRuleEnrollment: promotionCompleteState,
          },
        },
      })
    }
    updateTriggerRuleData(triggerRuleData)
  }

  const handleEnrollmentTypeChange = (value: boolean, id: string): void => {
    if (value) {
      setSelectedEnrollment('awardOnEnrollment')
      handleEnrollmentChange('awardOnEnrollment')
    } else {
      setSelectedEnrollment('earningPoints')
      handleEnrollmentChange('earningPoints')
    }
    setEnrollmentType(value)
  }
  const handleConditionalTypeChange = (value: string, id: string): void => {
    setSelectedEnrollment(value)
    setEarnPointState(initialEarnPointState)
    setPromotionCompleteState(initialPromotionCompleteState)
    handleEnrollmentChange(value)
  }

  const handleSetEnrollmetData = (value: FormTypes.ValueType, id: string): void => {
    let prevState = cloneDeep(earnPointState)
    prevState = {
      ...prevState,
      [id]: value,
    }
    setEarnPointState(prevState)
    setTriggerRuleData({
      triggerData: [],
      activityRuleData: null,
      eventRuleData: null,
      limitData: null,
      multiCounterData: null,
      spendRuleData: null,
      enrollmentRuleData: {
        regularRuleEnrollment: null,
        condititonalRuleEnrollment: {
          earnPointRuleEnrollment: prevState,
          promotionRuleEnrollment: null,
        },
      },
    })
  }
  const handlePromotionCompleteEnrollmetData = (value: FormTypes.ValueType, id: string): void => {
    let prevState = cloneDeep(promotionCompleteState)
    if (id === 'promotionCode') {
      prevState = {
        ...prevState,
        promotionCode: value as string,
        promotionName: orgnlPromotionsDataOptions.filter((x) => x.value === value)[0].label as string,
      }
    } else {
      prevState = {
        ...prevState,
        [id]: Number(value),
      }
    }
    setPromotionCompleteState(prevState)
    setTriggerRuleData({
      triggerData: [],
      activityRuleData: null,
      eventRuleData: null,
      limitData: null,
      multiCounterData: null,
      spendRuleData: null,
      enrollmentRuleData: {
        regularRuleEnrollment: null,
        condititonalRuleEnrollment: {
          earnPointRuleEnrollment: null,
          promotionRuleEnrollment: prevState,
        },
      },
    })
  }

  const setSpendTriggerData = (value: FormTypes.ValueType, id: string): void => {
    const spenRuleData: PromotionsTypes.SpendData = triggerRuleData?.spendRuleData ?? {
      spendType: spendType ? 'LIMITEDPERIOD' : 'ENROLLMENTPERIOD',
      spendValue: 0,
      timePeriod: null,
      withiEnrollmentDays: 0,
    }
    if (id === 'spendValue') {
      setSpendValue(value as number)
      if (value && triggerRuleData) {
        setTriggerRuleData({
          ...triggerRuleData,
          spendRuleData: {
            ...spenRuleData,
            spendValue: value as number,
          },
        })
      }
    } else if (id === 'pointsMilestone') {
      // setPointsMilestone(value as number)
    } else if (id === 'daysAfterEnroll') {
      setDaysAfterEnroll(value as number)
      if (value && triggerRuleData && triggerRuleData.spendRuleData) {
        setTriggerRuleData({
          ...triggerRuleData,
          spendRuleData: {
            ...spenRuleData,
            withiEnrollmentDays: value as number,
          },
        })
      }
    } else if (id === 'timePeriod') {
      setTimePeriod(value as string[])

      if (value && triggerRuleData && triggerRuleData.spendRuleData) {
        // console.log(dayjs.utc(value as string[][0], ))
        const timePeriod = value as string[]

        setTriggerRuleData({
          ...triggerRuleData,
          spendRuleData: {
            ...spenRuleData,
            timePeriod: {
              to: dayjs(timePeriod[1]).endOf('day').format(Appconfig.DATE_TIME_BIRTHDAY_DATE),
              from: dayjs(timePeriod[0]).startOf('day').format(Appconfig.DATE_TIME_BIRTHDAY_DATE),
            },
          },
        })
      }
    }
  }

  // const onMatchDateRangeClick = (value: string[], id: string): void => {
  //   const checkedValue = value[0]
  //   setIsChecked(checkedValue)
  // }

  // const onUntilPromoEndDateClick = (value: string[], id: string): void => {
  //   const checkedValue = value[0]
  //   setIsUntilPromoEndDate(checkedValue)
  // }

  const renderTriggerLimits = () => {
    return (
      <TriggerLimitsSection>
        <Typography.Title level={5} uppercase>
          Set Limit(s)
        </Typography.Title>
        <TriggerLimitsWrapper>
          {limitOptions.map((limit) => {
            return (
              <TriggerLimit
                ref={triggerLimitRef}
                options={[limit]}
                setTriggerLimits={setTriggerLimits}
                setTriggerLimitsData={setTriggerLimitsData}
                data={limit.value === 'userLimit' ? limitInfo?.userLimit : limitInfo?.campaignLimit}
                isReset={isResetLimit}
              />
            )
          })}
        </TriggerLimitsWrapper>
      </TriggerLimitsSection>
    )
  }

  const renderCategoryBody = () => {
    if (isCategoryLoading) {
      return <LoadingPanel />
    }

    switch (activePromotionType) {
      case 'TRANSACTION':
        return (
          <>
            <PromotionTypeDetailsWrapper>
              <Typography.Title level={5} uppercase>
                Transaction Details
              </Typography.Title>
              {triggerRuleData &&
                triggerRuleData.triggerData &&
                triggerRuleData.triggerData.length > 0 &&
                triggerRuleData.triggerData.map((data, index) => {
                  const hasError = errorIndex.includes(index)
                  return (
                    <CategoryRowWrapper $hasError={hasError} key={index}>
                      <CategoryRow
                        onDeleteRow={handleDeleteRow}
                        rowIndex={index}
                        triggerData={data}
                        categories={categoryOptions()}
                        onCategoryChange={handleCategoryChange}
                        onCategoryValueChange={handleCategoryValueChange}
                        onCategoryActionChange={handleCategoryActionChange}
                      />
                    </CategoryRowWrapper>
                  )
                })}
              <Button type="text" size="small" onClick={handleAddAttr} color={Colors.TEXT_COLOR_PRIMARY}>
                <strong>+ Add Attribute</strong>
              </Button>
            </PromotionTypeDetailsWrapper>
          </>
        )
      case 'ACTIVITY':
        return (
          <>
            <PromotionTypeDetailsWrapper>
              <Typography.Title level={5} uppercase>
                Select Activities
              </Typography.Title>
              <PromotionTypeDetailsBody>
                <VerticalRadioWrapper>
                  <Form.Radio
                    boxed
                    id="activity"
                    value={selectedActivity}
                    onChange={handleActivityChange}
                    options={triggerActivitiesOptions}
                    align="vertical"
                    onShowInfo={handleShowActivityDetails}
                    activeItem={activeActivity}
                  />
                </VerticalRadioWrapper>
                {selectedActivity === 'Redemption' && (
                  <AddMoreAttributesWrapper>
                    {loadingRewards ? (
                      <LoadingPanel />
                    ) : (
                      <Form.Select
                        id="rewards"
                        label="Select Rewards"
                        mode="multiple"
                        value={selRewards}
                        options={rewardsDataOptions}
                        onChange={handleSelectRewards}
                        filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                      />
                    )}
                  </AddMoreAttributesWrapper>
                )}
              </PromotionTypeDetailsBody>
            </PromotionTypeDetailsWrapper>
          </>
        )
      case 'ENROLLMENT':
        return (
          <PromotionTypeDetailsWrapper>
            <MarginBottomWrapper>
              <Typography.Title level={5} uppercase>
                Enrollment Detail
              </Typography.Title>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <ToggleButton
                id="enrollment"
                value={enrollmentType}
                values={EnrollmentTypeOptions}
                handleChange={handleEnrollmentTypeChange}
                useAsButton={true}
              />
            </MarginBottomWrapper>
            {enrollmentType ? (
              <Typography.Text>All the members who get enrolled will avail the benefits.</Typography.Text>
            ) : (
              <>
                <MarginBottomWrapper>
                  <Form.Radio boxed id="enrollment" value={selectedEnrollment} onChange={handleConditionalTypeChange} options={EnrollmentOptions} />
                </MarginBottomWrapper>
                {selectedEnrollment && (
                  <Row gutter={24}>
                    {selectedEnrollment === 'earningPoints' ? (
                      <>
                        <Col span={8}>
                          <Form.Select
                            id="pointTypeCode"
                            label="Select a Point Type"
                            placeholder="Search and Select Point Type"
                            onChange={handleSetEnrollmetData}
                            filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                            value={earnPointState.pointTypeCode}
                            options={pointTypeOptions}
                          />
                        </Col>
                        <Col span={8}>
                          <Form.InputNumber
                            id="points"
                            type="number"
                            label="Points"
                            placeholder="eg. 40,000"
                            value={earnPointState.points as number}
                            min={0}
                            max={10000}
                            width={'100%'}
                            onChange={handleSetEnrollmetData}
                          />
                        </Col>
                        <Col span={8}>
                          <Form.InputNumber
                            id="days"
                            label="Within Days After Enrollment"
                            placeholder="eg. 20 days"
                            value={earnPointState.days as number}
                            min={0}
                            max={10000}
                            onChange={handleSetEnrollmetData}
                          />
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={8}>
                          <Form.Select
                            id="promotionCode"
                            label="Select a promotion"
                            placeholder="Search and Select promotion"
                            onChange={handlePromotionCompleteEnrollmetData}
                            filterOption={(input, option) => ((option?.label as string) ?? '').toLowerCase().includes(input.toLowerCase())}
                            value={promotionCompleteState.promotionCode}
                            options={orgnlPromotionsDataOptions}
                          />
                        </Col>
                        <Col span={8}>
                          <Form.InputNumber
                            id="totalDays"
                            label="Within Days After Enrollment"
                            placeholder="eg. 20 days"
                            value={promotionCompleteState.totalDays as number}
                            min={0}
                            max={10000}
                            onChange={handlePromotionCompleteEnrollmetData}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                )}
              </>
            )}
          </PromotionTypeDetailsWrapper>
        )
      case 'EVENT':
        return (
          <PromotionTypeDetailsWrapper>
            <Typography.Title level={5} uppercase>
              Select Event
            </Typography.Title>
            {isPointTypeLoading ? (
              <LoadingPanel />
            ) : (
              <EventWrapper>
                <MarginRightWrapper value={'32px'}>
                  <Form.Radio boxed id="event" value={selectedEvent} onChange={handleEventChange} options={EventOptions} align="vertical" />
                </MarginRightWrapper>
                {
                  <EventPointWrapper>
                    <Row gutter={24}>
                      <Col span={12}>
                        {selectedEvent === 'Point' && (
                          <Form.Select
                            id="pointTypeCode"
                            label="Select a Point Type"
                            onChange={handlePointTypeChange}
                            value={pointTypeCode}
                            options={pointTypeOptions}
                            noMargin
                          />
                        )}
                      </Col>
                      <Col span={selectedEvent === 'Point' ? 12 : 24}>
                        {(selectedEvent === 'Point' || selectedEvent === 'membershipLifeTimeValue') && (
                          <Form.InputNumber
                            id="eventValue"
                            type="number"
                            label="Threshold Points"
                            value={eventValue}
                            min={0}
                            max={Number.MAX_SAFE_INTEGER}
                            width={'100%'}
                            onChange={handleThresholdChange}
                          />
                        )}
                      </Col>
                    </Row>
                  </EventPointWrapper>
                }
              </EventWrapper>
            )}
          </PromotionTypeDetailsWrapper>
        )
      case 'MULTICOUNTER':
        return (
          <PromotionTypeDetailsWrapper>
            <MarginBottomWrapper>
              <Typography.Title level={5} uppercase>
                Select MultiCounter
              </Typography.Title>
            </MarginBottomWrapper>
            {isFetchingPromotions ? (
              <LoadingPanel />
            ) : (
              <MarginBottomWrapper>
                {promoCounter.map((promoRow, index) => (
                  <MultiCounterRow
                    rowIndex={index}
                    data={promotionsDataOptions}
                    originaldata={orgnlPromotionsDataOptions}
                    promoCounterData={promoCounter}
                    promoCounterItem={promoRow}
                    handleUpdateRow={handleUpdateMultiCounterRow}
                    handleDeleteRow={handleDeleteMultiCounterRow}
                  />
                ))}
              </MarginBottomWrapper>
            )}
            {promoCounter.length < 5 && (
              <MarginBottomWrapper>
                <Button type="text" onClick={handleAddRow} color={Colors.BLACK} $startIcon={<IconAdd color={Colors.BLACK} />}>
                  Add Row
                </Button>
              </MarginBottomWrapper>
            )}
          </PromotionTypeDetailsWrapper>
        )
      case 'SPEND':
        return (
          <PromotionTypeDetailsWrapper>
            <MarginBottomWrapper>
              <Typography.Title level={5} uppercase>
                Spend Detail
              </Typography.Title>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <ToggleButton id="spendType" value={spendType} values={SpendTypeOptions} handleChange={handleSpendTypeChange} useAsButton={true} />
            </MarginBottomWrapper>
            {spendType ? (
              <>
                <MarginBottomWrapper>
                  <Row>
                    <Col span={8}>
                      <Form.InputNumber
                        id="spendValue"
                        type="number"
                        label="Spend Value Milestone"
                        placeholder="Enter Spend Value"
                        value={spendValue}
                        min={0}
                        max={1000000}
                        addOnAfter={
                          <Typography.Title level={5} color="default">
                            $
                          </Typography.Title>
                        }
                        width={'100%'}
                        onChange={setSpendTriggerData}
                      />
                    </Col>
                  </Row>
                </MarginBottomWrapper>
                <Row>
                  <Col span={8}>
                    <Form.DateRangePicker
                      id="timePeriod"
                      label="Time Period"
                      rangePickerProps={{
                        onChange: setSpendTriggerData,
                        value: timePeriod,
                      }}
                    />
                  </Col>
                  {/* <Col span={24}>
                    <CheckboxContainer>
                      <Form.Checkbox
                        id="matchDateRange"
                        options={matchDateRangeCheckboxOptions}
                        value={[isChecked]}
                        onChange={onMatchDateRangeClick}
                      />
                    </CheckboxContainer>
                  </Col> */}
                </Row>
              </>
            ) : (
              <>
                <MarginBottomWrapper>
                  <Row>
                    <Col span={8}>
                      <Form.InputNumber
                        id="spendValue"
                        type="number"
                        label="Spend Value Milestone"
                        placeholder="Enter Spend Value"
                        value={spendValue}
                        min={0}
                        max={1000000}
                        addOnAfter={
                          <Typography.Title level={5} color="default">
                            $
                          </Typography.Title>
                        }
                        width={'100%'}
                        onChange={setSpendTriggerData}
                      />
                    </Col>
                  </Row>
                </MarginBottomWrapper>
                {/* <MarginBottomWrapper>
                  <Row>
                    <Col span={8}>
                      <Form.InputNumber
                        id="pointsMilestone"
                        type="number"
                        label="Points Milestone"
                        placeholder="Enter Points"
                        value={pointsMilestone}
                        min={0}
                        max={10000}
                        width={'100%'}
                        onChange={setSpendTriggerData}
                      />
                    </Col>
                  </Row>
                </MarginBottomWrapper> */}
                <MarginBottomWrapper>
                  <Row>
                    <Col span={6}>
                      <Form.InputNumber
                        id="daysAfterEnroll"
                        type="number"
                        label="Within Days After Enrollment"
                        placeholder="eg. 20 days"
                        value={daysAfterEnroll}
                        min={0}
                        max={1000000}
                        width={'100%'}
                        onChange={setSpendTriggerData}
                      />
                    </Col>
                  </Row>
                </MarginBottomWrapper>
              </>
            )}
          </PromotionTypeDetailsWrapper>
        )
      default:
    }
    return null
  }

  return (
    <div>
      <SetpsBody>
        <Tabs>
          <Tabs.Tab label="Transactions" id={0} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Activity" id={1} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Enrollment" id={2} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Event" id={3} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Multi Counter" id={4} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
          <Tabs.Tab label="Spend" id={5} onTabChange={handleOnTabChange} activeTabId={activeTabId} />
        </Tabs>
        {renderCategoryBody()}
        {activePromotionType !== 'MULTICOUNTER' && renderTriggerLimits()}
      </SetpsBody>
    </div>
  )
})

export default Trigger
