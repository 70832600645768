import {ErrorBoundry} from '../common/components/Routes/Fallback'
import MemberSearch from '../pages/Member/MemberSearch'
import {RouteObject} from 'react-router'
import MemberDetail from 'app/pages/Member/MemberDetail'
import Member from 'app/pages/Member'

const MemberRoutes: RouteObject = {
  path: `member`,
  element: <Member />,
  errorElement: <ErrorBoundry />,
  children: [
    {index: true, element: <MemberSearch />},
    {path: 'search', element: <MemberSearch />},
    {path: `:membershipId`, element: <MemberDetail />},
  ],
}

export default MemberRoutes
