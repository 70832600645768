import {useEffect, useState} from 'react'
import {func, number} from 'prop-types'
import {useNavigate} from 'react-router-dom'

import MembershipService from 'app/services/MemberService'
import SearchResult from './SearchResult'
import Appconfig from 'app/common/helpers/AppConfig'

interface MemberSearchTableProps {
  showSearch?: boolean
  showTable?: boolean
  pageSize?: number
  rowClick?(row: MemebershipTypes.MembershipDataBL): void
  pageSizeOptions?: number[]
  showMemberDetail?: boolean
}

const MemberSearchTable: React.FC<MemberSearchTableProps> = ({
  pageSize = Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
  rowClick,
  showSearch,
  showTable,
  showMemberDetail,
}) => {
  const navigate = useNavigate()
  const initialMembershipResponse: ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }

  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: pageSize,
    PageNumber: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_NUMBER,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }

  const [isFetching, setIsFetching] = useState(true)
  const [memberResponse, setMemberResponse] = useState<ResponseTypes.PagedResponse<MemebershipTypes.MembershipDataBL[]>>(initialMembershipResponse)
  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)

  const getMemebers = (params: MemebershipTypes.MembershipDetail) => {
    MembershipService.searchMember(params)
      .then((res) => {
        const data = res.data
        setMemberResponse(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPaginationObj({...paginationObj, PageCount: pageSize, PageNumber: page})
  }

  useEffect(() => {
    if (showTable) getMemebers(paginationObj)
  }, [])

  const handleSearch = (searchData?: MemebershipTypes.MembershipDetail) => {
    setIsFetching(true)

    getMemebers({
      ...paginationObj,
      ...searchData,
    })
  }

  const handleRowClick = (row: MemebershipTypes.MembershipDataBL) => {
    if (rowClick) {
      rowClick(row)
      return
    }
    const encodedMemberShipId = btoa(row.membershipCustomerId)
    navigate(`/member/${encodedMemberShipId}`)
  }

  return (
    <SearchResult
      handleSearch={handleSearch}
      isFetching={isFetching}
      memberResponse={memberResponse}
      rowClick={handleRowClick}
      pageSizeOptions={pageSizeOptions}
      handlePaginationChange={handlePaginationChange}
      paginationObj={paginationObj}
      showSearch={showSearch}
      showTable={showTable}
      showMemberDetail={showMemberDetail}
    />
  )
}
MemberSearchTable.defaultProps = {
  pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
  pageSizeOptions: Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS,
}

MemberSearchTable.propTypes = {
  pageSize: number,
  rowClick: func,
}

export default MemberSearchTable
