import { Tooltip } from 'antd'
import { MEMBER_DETAIL } from 'app/common/helpers/UserFunctions'
import IconEye from 'app/common/icons/IconEye'
import { useAuth } from 'app/pages/Auth/AuthContext'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import { useNavigate } from 'react-router-dom'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'memberName',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Profile Information',
    id: 'profileInfo',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Email Address',
    id: 'email',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Gender',
    id: 'gender',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Date Of birth',
    id: 'dateOfBirth',
    textAlign: 'center',
    width: '15%',
  },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Actions',
    id: 'actions',
    textAlign: 'left',
    width: '10%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  segmentsResponse: ResponseTypes.PagedResponse<SegmentationTypes.SegmentSearchResponse[]>
  handleSearch: (value: boolean, voucherTypeCode: string) => void
}

const SearchResults = ({isFetching, segmentsResponse, handleSearch}: SearchResultProps) => {
  const navigate = useNavigate()
  const {roles} = useAuth()

  const handleView = (rowIndex: number) => {
    const membershipCode = segmentsResponse.data[rowIndex].membershipCustomerId
    const encodedMemberShipId = btoa(membershipCode)
    navigate(`/member/${encodedMemberShipId}`)
  }

  const renderRowActions = (rowIndex: number) => {
    return (
      <>
        {(roles.includes(MEMBER_DETAIL)) && (
          <Tooltip title="View">
            <Button shape="circle" size="small" ghost icon={<IconEye size={16} />} onClick={() => handleView(rowIndex)} />
          </Tooltip>
        )}
      </>
    )
  }
  const generateRowData = (): DataTableTypes.Row[] => {
    if (segmentsResponse && segmentsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = segmentsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          memberName: item.membershipFullName,
          profileInfo: item.membershipAddress1 ?? '' + item.membershipAddress2 ?? '' + item.membershipState ?? '' + item.membershipPhone ?? '',
          email: item.membershipEmailId,
          gender: item.membershipGender,
          dateOfBirth: item.membershipBirthDate?.split('T')[0],
          status: item.membershipStatusKey,
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })
      return dataTableRows
    }
    return []
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
    </>
  )
}

export default SearchResults
