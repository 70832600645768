import {useEffect, useState} from 'react'
import Colors from 'styles/Colors'
import Typography from 'lib/Typography'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconChevronUp from 'app/common/icons/IconChevronUp'
import MergeAccounts from './MergeAccounts'
import {QuickActionContainer, QuickActionList, QuickActionListItem, QuickActionsTitle} from './QuickActions.styled'
import UpdateStatus from './UpdateStatus'
import UpdateTierName from './UpdateTierName'
import Button from 'lib/components/Button'
import {utcWithTimeZone} from 'utils/convertDateToString'
import PromotionService from 'app/services/PromotionService'
import {Tooltip} from 'antd'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {
  MEMBER_DETAIL_CHANGE_STATUS,
  MEMBER_DETAIL_CHANGE_TIER,
  MEMBER_DETAIL_ISSUE_REWARD,
  MEMBER_DETAIL_MERGE,
  MEMBER_DETAIL_OPTIN,
  MEMBER_DETAIL_STATUS,
  MEMBER_DETAIL_TIER,
} from 'app/common/helpers/UserFunctions'

type AccordioonName = 'merge' | 'tier' | 'status' | string

interface QuickActionsProps {
  memberDetail: MemebershipTypes.MemberDataDetailBL
  setMemberDetail: React.Dispatch<React.SetStateAction<MemebershipTypes.MemberDataDetailBL | undefined>>
  memberUpdate: () => void
  handleIssueReward: () => void
  handleOptIn(): void
}

const QuickActions: React.FC<QuickActionsProps> = ({memberDetail, setMemberDetail, memberUpdate, handleIssueReward, handleOptIn}) => {
  const [accordionName, setAccordionName] = useState<AccordioonName>('')
  const [promotionCount, setPromotionCount] = useState(0)
  const {roles} = useAuth()
  const showTier = roles.includes(MEMBER_DETAIL_TIER)
  const showMemberStatus = roles.includes(MEMBER_DETAIL_STATUS)
  const showMemberChangeStatus = roles.includes(MEMBER_DETAIL_CHANGE_STATUS)
  const showMemberChangeTier = roles.includes(MEMBER_DETAIL_CHANGE_TIER)
  const showMemberIssueReward = roles.includes(MEMBER_DETAIL_ISSUE_REWARD)
  const showMemberOptin = roles.includes(MEMBER_DETAIL_OPTIN)
  const showMemberMerge = roles.includes(MEMBER_DETAIL_MERGE)

  const initialSearchParms: PromotionsTypes.PromotionSearchParam = {}
  const handleToggleAccordion = (name: AccordioonName) => {
    if (name === accordionName) {
      setAccordionName('')
    } else {
      setAccordionName(name)
    }
  }

  const handleSearch = (params: PromotionsTypes.PromotionSearchParam) => {
    const now = new Date().toISOString()
    const reqParam: PromotionsTypes.PromotionSearchParam = {
      // IsCombinable: false,
      ...params,
      PromotionStatusCode: 'APPROVED' || 'ACTIVE',
      PromotionCategory: 'OPTIN',
      StartDateTime: utcWithTimeZone(now),
    }

    PromotionService.search(reqParam)
      .then((res) => {
        const data = res.data
        // console.log(data.data.length)
        setPromotionCount(data.data.length)
      })
      .finally(() => {})
  }

  useEffect(() => {
    handleSearch({...initialSearchParms})
  }, [])

  const optedButton = () => {
    return (
      <Button
        onClick={handleOptIn}
        color="primary"
        type="text"
        size="small"
        disabled={promotionCount === 0}
        $endIcon={accordionName === 'status' ? <IconChevronUp size={16} /> : <IconChevronRight size={16} />}
      ></Button>
    )
  }

  return (
    <QuickActionContainer>
      {(showMemberStatus || showTier) && (
        <QuickActionsTitle>
          <Typography color={Colors.GREY4} uppercase variant="subtitle">
            Quick Actions
          </Typography>
        </QuickActionsTitle>
      )}

      {showMemberMerge && (
        <QuickActionList>
          <QuickActionListItem>
            <div>
              <Typography component="div" variant="h5">
                Merge accounts
              </Typography>
            </div>
            <div>
              <Button
                onClick={() => handleToggleAccordion('merge')}
                type="text"
                size="small"
                $endIcon={accordionName === 'merge' ? <IconChevronUp size={16} /> : <IconChevronRight size={16} />}
              >
                {accordionName === 'merge' ? 'Close' : 'Select account'}
              </Button>
            </div>
          </QuickActionListItem>
          {accordionName === 'merge' && (
            <MergeAccounts memberDetail={memberDetail} handleToggleAccordion={handleToggleAccordion} showMemberMerge={showMemberMerge} />
          )}
        </QuickActionList>
      )}

      {showTier && (
        <QuickActionList>
          <QuickActionListItem>
            <div>
              <Typography variant="label">Tier Level</Typography>
              <Typography component="div" variant="h5">
                {/* Tier name */}
                {memberDetail?.membershipTierData?.membershipTierName}
              </Typography>
            </div>
            {showMemberChangeTier && (
              <div>
                <Button
                  onClick={() => handleToggleAccordion('tier')}
                  type="text"
                  size="small"
                  $endIcon={accordionName === 'tier' ? <IconChevronUp size={16} /> : <IconChevronRight size={16} />}
                >
                  {accordionName === 'tier' ? 'Close' : 'Change'}
                </Button>
              </div>
            )}
          </QuickActionListItem>
          {accordionName === 'tier' && showMemberChangeTier && (
            <UpdateTierName memberDetail={memberDetail} handleToggleAccordion={handleToggleAccordion} memberUpdate={memberUpdate} />
          )}
        </QuickActionList>
      )}
      {showMemberIssueReward && (
        <QuickActionList>
          <QuickActionListItem>
            <div>
              <Typography variant="label">Manual Redemption</Typography>
              <Typography component="div" variant="h5">
                Issue Reward
              </Typography>
            </div>
            <div>
              <Button
                onClick={handleIssueReward}
                color="primary"
                type="text"
                size="small"
                $endIcon={accordionName === 'status' ? <IconChevronUp size={16} /> : <IconChevronRight size={16} />}
              ></Button>
            </div>
          </QuickActionListItem>
        </QuickActionList>
      )}

      {showMemberStatus && (
        <QuickActionList>
          <QuickActionListItem>
            <div>
              <Typography variant="label">Status Level</Typography>
              <Typography component="div" variant="h5">
                {memberDetail.membershipStatusData.membershipStatusName}
              </Typography>
            </div>
            {showMemberChangeStatus && (
              <div>
                <Button
                  onClick={() => handleToggleAccordion('status')}
                  color="primary"
                  type="text"
                  size="small"
                  $endIcon={accordionName === 'status' ? <IconChevronUp size={16} /> : <IconChevronRight size={16} />}
                >
                  {accordionName === 'status' ? 'Close' : 'Change'}
                </Button>
              </div>
            )}
          </QuickActionListItem>
          {accordionName === 'status' && showMemberChangeStatus && (
            <UpdateStatus memberDetail={memberDetail} handleToggleAccordion={handleToggleAccordion} memberUpdate={memberUpdate} />
          )}
        </QuickActionList>
      )}
      {showMemberOptin && (
        <QuickActionList>
          <QuickActionListItem>
            <div>
              {/* <Typography variant="label">Manual Redemption</Typography> */}
              <Typography component="div" variant="h5">
                Opt-In to a Promotion
              </Typography>
            </div>
            <div>
              {promotionCount === 0 ? (
                <Tooltip title="No active opt-in promotions are available for this member">
                  <span>{optedButton()}</span>
                </Tooltip>
              ) : (
                optedButton()
              )}
            </div>
          </QuickActionListItem>
        </QuickActionList>
      )}
    </QuickActionContainer>
  )
}

export default QuickActions
