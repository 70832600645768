// import {Tooltip} from 'antd'
// import IconEdit from 'app/common/icons/IconEdit'
// import IconEye from 'app/common/icons/IconEye'
import RewardService from 'app/services/RewardService'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import ToggleButton from 'lib/components/ToggleButton'
import {ToggleOptionType} from 'lib/components/ToggleButton/ToggleButton'
import {toast} from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'
import {getCatgeoryNames, getRewardsRequestObj, rewardStatus} from '../AddNewRewrds/utils'
import {useAuth} from 'app/pages/Auth/AuthContext'
import {REWARD_ACTIVATE, REWARD_DETAIL, REWARD_EDIT, REWARD_PREVIEW} from 'app/common/helpers/UserFunctions'
import {useState} from 'react'
import IconDelete from 'app/common/icons/IconDelete'
import {exclusionStatusForDelete} from 'app/pages/Promotions/CreatePromotion/utils'
import Popover from 'lib/components/Popover'
import IconThreeDots from 'app/common/icons/IconThreeDots'
import {MarginBottomWrapper, ModalTitle, Seperator, StyledMenuItem, StyledModalContent} from 'app/common/components/Styled/common.styled'
import IconChevronRight from 'app/common/icons/IconChevronRight'
import IconAddMenu from 'app/common/icons/IconAddMenu'
import {Col, Modal, Row} from 'antd'
import Typography from 'lib/components/Typography'
import {InputNumberUnit, InputNumberWrapper, ModalActionStyled, StyledAddQtyWrapper, StyledReviewLabel, StyledReviewValue} from '../Reward.Styled'
import Form from 'lib/components/Form'
import {FormTypes} from 'lib/components/Form/Form'
import IconSuccess from 'app/common/icons/IconSuccess'
import IconArrowRightLong from 'app/common/icons/IconArrowRightLong'
import Colors from 'styles/Colors'
import {utcDate, utcWithTimeZone} from 'utils/convertDateToString'

import dayjs from 'dayjs'
import {handleDisableFromDate, handleDisableToDate} from 'utils/DatepickerUtils'
import Appconfig from 'app/common/helpers/AppConfig'
import {cloneDeep} from 'lodash'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Name',
    id: 'voucherTypeName',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Id',
    id: 'voucherTypeId',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Description',
    id: 'description',
    textAlign: 'left',
    width: '22%',
  },
  {
    title: 'Cost/ Points',
    id: 'point',
    textAlign: 'center',
    width: '8%',
  },
  {
    title: 'Cost/ Dollars',
    id: 'amount',
    textAlign: 'center',
    width: '8%',
  },
  // {
  //   title: 'Current Inventory',
  //   id: 'quantity',
  //   textAlign: 'center',
  //   width: '8%',
  // },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'center',
    width: '12%',
  },
  {
    title: 'On/Off',
    id: 'toggle',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: '',
    id: 'actions',
    textAlign: 'left',
    width: '10%',
  },
]

const activateOptions: ToggleOptionType[] = [
  {
    label: 'On',
    value: true,
  },
  {
    label: 'Off',
    value: false,
  },
]

interface SearchResultProps {
  isFetching: boolean
  rewardsResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>
  handleSearch: (value: boolean, voucherTypeCode: string) => void
  handleDeleteReward(reward: RewardsTypes.RewardResponseBL): void
  categoryDataOptions: FormTypes.Options[]
  handleRefreshData(): void
}

const SearchResults = ({
  isFetching,
  rewardsResponse,
  handleSearch,
  handleDeleteReward,
  categoryDataOptions,
  handleRefreshData,
}: SearchResultProps) => {
  const navigate = useNavigate()
  const {roles} = useAuth()
  const [selectedRewardIndex, setSelectedRewardIndex] = useState<number>(-1)
  const showActivateRewards = roles.includes(REWARD_ACTIVATE)
  const showReviewRewards = roles.includes(REWARD_PREVIEW) || roles.includes(REWARD_DETAIL)
  const showEditRewards = roles.includes(REWARD_EDIT)
  const [isProgress, setIsProgress] = useState<null | number>(null)
  const [openQtyModal, setOpenQtyModal] = useState<boolean>(false)
  const [openPriceSaleModal, setOpenPriceSaleModal] = useState<boolean>(false)
  const [currentReward, setCurrentReward] = useState<RewardsTypes.RewardResponseBL | null>(null)
  const [voucherLimit, setVoucherLimit] = useState<number>(0)
  const [updatingQty, setUpdatingQty] = useState<boolean>(false)
  const [updatingPriceHistory, setUpdatingPriceHistory] = useState<boolean>(false)
  const [qtyUpdateSuccess, setQtyUpdateSuccess] = useState<boolean>(false)
  const [priceSaleUpdateSuccess, setPriceSaleUpdateSuccess] = useState<boolean>(false)
  const [currentqty, setCurrentqyt] = useState(0)
  const initialState: RewardsTypes.RewardPriceHistoryState = {
    voucherTypeName: '',
    voucherTypeCode: '',
    partnerCode: '',
    point: 0,
    validFrom: utcDate(utcWithTimeZone(new Date().toISOString()), Appconfig.DATE_TIME_FORMAT),
    validUntil: null,
  }
  const [state, setState] = useState<RewardsTypes.RewardPriceHistoryState>(initialState)
  const handleChange = (value: FormTypes.ValueType, key: string): void => {
    setVoucherLimit(value as number)
  }

  const handleStateChange = (value: FormTypes.ValueType, key: string) => {
    const prevState = cloneDeep(state)
    setState({
      ...prevState,
      [key]: value,
    })
  }

  const handleEdit = (rowIndex: number) => {
    const voucherCode = rewardsResponse.data[rowIndex].voucherTypeCode
    const encodedVoucherCode = btoa(voucherCode)
    navigate(`/rewards/${encodedVoucherCode}`)
  }

  const handleView = (rowIndex: number) => {
    const voucherCode = rewardsResponse.data[rowIndex].voucherTypeCode
    const encodedVoucherCode = btoa(voucherCode)
    navigate(`/rewards/view/${encodedVoucherCode}`)
  }

  const saveRewards = (value: boolean, voucherData: RewardsTypes.RewardResponseBL) => {
    RewardService.saveRewards(voucherData, {voucherTypeCode: voucherData.voucherTypeCode})
      .then((res) => {
        handleSearch(value, voucherData.voucherTypeCode)
        toast.success('Data Saved Successfully!')
      })
      .catch(() => {
        toast.error('Error in fetching RewardService ')
      })
      .finally(() => {
        setIsProgress(null)
      })
  }

  const handleActivate = (value: boolean, key: string, rowIndex: number): void => {
    let voucherData = rewardsResponse.data[rowIndex]
    RewardService.searchDetail({voucherTypeCode: voucherData.voucherTypeCode})
      .then((res) => {
        const data = res.data.data
        voucherData = data
      })
      .finally(() => {
        voucherData = {
          ...voucherData,
          enabled: value,
        }
        setIsProgress(rowIndex)
        saveRewards(value, voucherData)
      })
  }

  const showAddQtyModal = async (item: RewardsTypes.RewardResponseBL) => {
    await getCurrentInventory(item.voucherTypeCode)
    setSelectedRewardIndex(-1)
    setCurrentReward(item)
    setOpenQtyModal(true)
  }
  const showAddsalePriceModal = async (item: RewardsTypes.RewardResponseBL) => {
    setSelectedRewardIndex(-1)
    setCurrentReward(item)
    setOpenPriceSaleModal(true)
  }

  const handleDelete = (rowIndex: number): void => {
    let voucherData = rewardsResponse.data[rowIndex]
    setSelectedRewardIndex(-1)
    handleDeleteReward(voucherData)
  }

  const handleOpenPopover = (visible: boolean, rowIndex: number): void => {
    setSelectedRewardIndex(visible ? rowIndex : -1)
  }

  const renderToggle = (rowIndex: number, item: RewardsTypes.RewardResponseBL) => {
    const reward = rewardsResponse.data[rowIndex]
    const isAtivationDisabled =
      reward.voucherStatus?.toUpperCase() === rewardStatus.APPROVED ||
      reward.voucherStatus?.toUpperCase() === rewardStatus.LIVE ||
      reward.voucherStatus?.toUpperCase() === rewardStatus.PENDING
    return (
      <>
        {showActivateRewards && (
          <ToggleButton
            id="activate"
            value={rewardsResponse.data[rowIndex].enabled}
            values={activateOptions}
            handleChange={(value, key) => handleActivate(value, key, rowIndex)}
            disabled={!isAtivationDisabled || isProgress === rowIndex}
          />
        )}
      </>
    )
  }

  const renderActionList = (rowIndex: number, item: RewardsTypes.RewardResponseBL) => {
    return (
      <>
        {showReviewRewards && (
          <StyledMenuItem title="View Reward">
            <Button type="text" onClick={() => handleView(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
              Details
            </Button>
          </StyledMenuItem>
        )}
        {showEditRewards && (
          <StyledMenuItem title="Edit">
            <Button type="text" onClick={() => handleEdit(rowIndex)} $endIcon={<IconChevronRight size={16} />}>
              Edit
            </Button>
          </StyledMenuItem>
        )}
        {showEditRewards && (
          <StyledMenuItem title="Edit">
            <Button
              type="text"
              onClick={() => showAddQtyModal(item)}
              $endIcon={<IconAddMenu />}
              disabled={item.voucherTypeGroup == 1 || item.voucherStatus !== 'APPROVED' || item.voucherTypeCategory?.toUpperCase() !== 'REWARD'}
            >
              Add Quantity
            </Button>
          </StyledMenuItem>
        )}
        {showEditRewards && (
          <StyledMenuItem title="Edit">
            <Button
              type="text"
              onClick={() => showAddsalePriceModal(item)}
              $endIcon={<IconAddMenu />}
              disabled={!(item.enabled && (item.voucherStatus === 'APPROVED' || item.voucherStatus === 'LIVE'))}
            >
              Add Sale Price
            </Button>
          </StyledMenuItem>
        )}
        {showEditRewards && !exclusionStatusForDelete.includes((item.voucherStatus || '').toUpperCase()) && (
          <StyledMenuItem title="Delete Reward">
            <Button type="text" onClick={() => handleDelete(rowIndex)} $endIcon={<IconDelete size={14} />}>
              Delete
            </Button>
          </StyledMenuItem>
        )}
      </>
    )
  }

  const renderRowActions = (rowIndex: number, item: RewardsTypes.RewardResponseBL) => {
    if (showEditRewards || showReviewRewards) {
      return (
        <Popover
          open={selectedRewardIndex !== -1}
          btnContent={<IconThreeDots />}
          content={renderActionList(rowIndex, item)}
          onOpenChange={(visible: boolean) => handleOpenPopover(visible, rowIndex)}
          rowIndex={rowIndex}
          selectedRowIndex={selectedRewardIndex}
        />
      )
    }
    return null
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (rewardsResponse && rewardsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = rewardsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          voucherTypeName: item.voucherTypeName,
          voucherTypeId: item.voucherTypeCode,
          description: item.voucherTypeDescription || item.voucherTypeFullDescription,
          point: item.point,
          amount: item.amount,
          quantity: item.voucherLimit,
          status: item.voucherStatus,
          toggle: renderToggle(index, item),
          actions: renderRowActions(index, item),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const getCurrentInventory = async (voucherTypeCode: string) => {
    const reqParam: RewardsTypes.RewardInfoSearchParam = {
      voucherStatus: 'AVAILABLE',
      voucherTypeCode: voucherTypeCode,
      Strict: true,
    }
    RewardService.searchRewards(reqParam)
      .then((res) => {
        const data = res.data
        setCurrentqyt(data.pageInfo.count)
        //setRewardsData(data)
      })
      .finally(() => {
        // setIsFetching(false)
      })
  }
  const handleOk = (): void => {
    const payload = {
      voucherTypeCode: currentReward?.voucherTypeCode || '',
      quantity: voucherLimit,
      validFrom: currentReward?.validFrom || utcWithTimeZone(new Date().toString()),
      validUntil: null,
      source: 'API',
    }
    setUpdatingQty(true)
    RewardService.generateVoucher(payload)
      .then((res) => {
        setQtyUpdateSuccess(true)
      })
      .finally(() => {
        setUpdatingQty(false)
      })
  }

  const handlePriceUpdate = (): void => {
    const payload: RewardsTypes.RewardPriceHistoryState = {
      voucherTypeCode: currentReward?.voucherTypeCode || '',
      voucherTypeName: currentReward?.voucherTypeName || '',
      partnerCode: currentReward?.partnerCode || '',
      point: state.point,
      validFrom: state?.validFrom || utcWithTimeZone(new Date().toString()),
      validUntil: state?.validUntil || null,
    }
    setUpdatingPriceHistory(true)
    RewardService.saveRewardPriceHistory(payload, {voucherTypeCode: undefined, partnerCode: undefined})
      .then((res) => {
        setPriceSaleUpdateSuccess(true)
      })
      .finally(() => {
        setUpdatingPriceHistory(false)
      })
  }
  const handleDateChange = (date: string | null, key: keyof RewardsTypes.RewardsWhenData) => {
    const prevState = cloneDeep(state)
    let dateTimeString = date ? dayjs(date).format() : null
    // if (key === 'validUntil') {
    //   dateTimeString = date ? dayjs(date).endOf('day').format() : null
    //   getRewardsRequestObj[key] = dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER)
    // } else {
    //   getRewardsRequestObj[key] = date ? dayjs(dateTimeString).endOf('day').format(Appconfig.DATE_TIME_SERVER) : null
    // }
    dateTimeString = dateTimeString ? dayjs(dateTimeString).format(Appconfig.DATE_TIME_SERVER) : null
    prevState[key] = dateTimeString
    getRewardsRequestObj[key] = dateTimeString
    setState(prevState)
  }
  const handleCancelModal = (refreshData?: boolean): void => {
    setVoucherLimit(0)
    setOpenQtyModal(false)
    setOpenPriceSaleModal(false)
    setState(initialState)
    if (refreshData) {
      handleRefreshData()
      setQtyUpdateSuccess(false)
      setPriceSaleUpdateSuccess(false)
    }
  }

  const getModalPriceHistoryContent = (): JSX.Element => {
    if (priceSaleUpdateSuccess) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              Sale price has been added!
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              A sale price for limited duration has been added to the reward {currentReward?.voucherTypeName}.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={() => handleCancelModal(true)}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={2} uppercase>
            Add Sale Price
          </Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Seperator />
        </MarginBottomWrapper>
        <StyledAddQtyWrapper>
          <Row gutter={32}>
            <Col xl={12}>
              <Form.DatePicker
                id="validFrom"
                disabledDate={(current: dayjs.Dayjs) => handleDisableFromDate(current, state.validFrom, state.validUntil)}
                value={state.validFrom}
                onChange={handleDateChange}
                label="Start Date"
                showTime
              />
            </Col>
            <Col xl={12}>
              <Form.DatePicker
                id="validUntil"
                disabledDate={(current: dayjs.Dayjs) => handleDisableToDate(current, state.validFrom, state.validUntil)}
                value={state.validUntil}
                onChange={handleDateChange}
                label="End Date"
                showTime
              />
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="point"
                  name="point"
                  label="Points Cost"
                  min={0}
                  max={1000000}
                  width={102}
                  value={state.point}
                  onChange={handleStateChange}
                  precision={0}
                />
                <InputNumberUnit>Points</InputNumberUnit>
              </InputNumberWrapper>
            </Col>
          </Row>
          {/* <Row>
            <Col span={24}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="amount"
                  name="amount"
                  label="Currency Cost"
                  min={0}
                  max={1000000}
                  width={102}
                  value={0}
                  onChange={handleStateChange}
                  precision={2}
                  disabled={true}
                />
                <InputNumberUnit>Dollars</InputNumberUnit>
              </InputNumberWrapper>
            </Col>
          </Row> */}
          {/* <Row gutter={32}>
            <Col span={24}>
              <InputNumberWrapper>
                <Form.InputNumber
                  id="value"
                  name="value"
                  label="Cost Per Point"
                  min={0}
                  max={1000000}
                  width={102}
                  value={0}
                  onChange={handleStateChange}
                  disabled={true}
                  precision={0}
                />
              </InputNumberWrapper>
            </Col>
          </Row> */}
        </StyledAddQtyWrapper>
        <ModalActionStyled>
          <Button type="text" onClick={() => handleCancelModal()}>
            Cancel
          </Button>
          <Button type="primary" loading={updatingPriceHistory} onClick={handlePriceUpdate} $endIcon={<IconArrowRightLong color={Colors.WHITE} />}>
            Save Changes
          </Button>
        </ModalActionStyled>
      </StyledModalContent>
    )
  }

  const getModalContent = (): JSX.Element => {
    if (qtyUpdateSuccess) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={2} uppercase>
              Inventory Info Updated
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              The inventory quantity and the re-order level has been updated for the Reward {currentReward?.voucherTypeName}.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="back" onClick={() => handleCancelModal(true)}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={2} uppercase>
            Update Inventory
          </Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Seperator />
        </MarginBottomWrapper>
        <StyledAddQtyWrapper>
          <Row>
            <Col span={12}>
              <StyledReviewLabel>Reward Name</StyledReviewLabel>
              <StyledReviewValue>{currentReward?.voucherTypeName}</StyledReviewValue>
            </Col>
            <Col span={12}>
              <StyledReviewLabel>Reward ID</StyledReviewLabel>
              <StyledReviewValue>{currentReward?.voucherTypeCode}</StyledReviewValue>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <StyledReviewLabel>Categories</StyledReviewLabel>
              <StyledReviewValue>{getCatgeoryNames(categoryDataOptions, currentReward?.categoryList || []).join(', ')}</StyledReviewValue>
            </Col>
            <Col span={12}>
              <StyledReviewLabel>Current Inventory</StyledReviewLabel>
              <StyledReviewValue>{currentqty}</StyledReviewValue>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.InputNumber
                id="voucherLimit"
                type="number"
                name="voucherLimit"
                label="Add Quantity"
                min={1}
                max={500}
                width={'100%'}
                value={voucherLimit}
                onChange={handleChange}
                precision={0}
              />
            </Col>
          </Row>
        </StyledAddQtyWrapper>
        <ModalActionStyled>
          <Button type="text" onClick={() => handleCancelModal()}>
            Cancel
          </Button>
          <Button type="primary" loading={updatingQty} onClick={handleOk} $endIcon={<IconArrowRightLong color={Colors.WHITE} />}>
            Update Info
          </Button>
        </ModalActionStyled>
      </StyledModalContent>
    )
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {openQtyModal && (
        <Modal open={openQtyModal} onCancel={() => handleCancelModal(true)} footer={null}>
          {getModalContent()}
        </Modal>
      )}
      {openPriceSaleModal && (
        <Modal open={openPriceSaleModal} onCancel={() => handleCancelModal(true)} footer={null}>
          {getModalPriceHistoryContent()}
        </Modal>
      )}
    </>
  )
}

export default SearchResults
