import Appconfig from 'app/common/helpers/AppConfig'
// import IconAdd from "app/common/icons/IconAdd"
// import { StyledHeader } from "app/pages/Promotions/Dashboard/Dashboard.styled"
import {ResetFormRefType} from 'app/pages/Promotions/Dashboard/PromotionsFilter'

import PromotionService from 'app/services/PromotionService'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
// import Typography from "lib/components/Typography"
import {useEffect, useRef, useState} from 'react'
import SearchResult from './SearchResult'
import OptInFilter, {PromotionsFilterState} from './OptInFilter'
import {Modal} from 'antd'
import {useNavigate} from 'react-router-dom'
import {ExtraPaddedButton, MarginBottomWrapper, ModalTitle, Seperator, StyledModalContent} from 'app/common/components/Styled/common.styled'
import Typography from 'lib/components/Typography'
import LoadingPanel from 'lib/components/LoadingPanel'
import IconSuccess from 'app/common/icons/IconSuccess'
import {StyledHeader} from 'app/pages/Promotions/Dashboard/Dashboard.styled'
import {utcWithTimeZone} from 'utils/convertDateToString'
import MembershipService from 'app/services/MemberService'
import toast from 'react-hot-toast'
// import Colors from "styles/Colors"

interface OptInProps {
  memberDetail: MemebershipTypes.MemberDataDetailBL
  handleClose(): void
  memberBalance: number
}

const OptIn: React.FC<OptInProps> = ({memberDetail, handleClose, memberBalance}) => {
  const FilterFormRef = useRef<ResetFormRefType>(null)
  const navigate = useNavigate()

  const initialResponse: ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]> = {
    data: [],
    message: '',
    pageInfo: {
      count: 0,
      hasNextPage: false,
      hasPreviousPage: false,
      pageIndex: 1,
      pageSize: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
      totalPages: 0,
    },
    links: {
      first: '',
      prev: '',
      href: '',
      next: '',
      last: '',
    },
  }
  const initialPaginationObj: PaginationType.PaginationDetails = {
    PageCount: Appconfig.DEFAULT_PAGINATION.DEFAULT_PAGE_SIZE,
    PageNumber: 1,
    SortColumn: 'UpdatedOn',
    Strict: false,
    SortDirection: Appconfig.DEFAULT_PAGINATION.DEFAULT_SORTING_ORDER,
  }
  // let paginationObj: PaginationType.PaginationDetails = {...initialPaginationObj}
  const now = new Date().toISOString()
  const initialSearchParms: PromotionsTypes.PromotionSearchParam = {
    PromotionStatusCode: 'APPROVED' || 'ACTIVE',
    PromotionCategory: 'OPTIN',
    StartDateTime: utcWithTimeZone(now),
  }

  const [paginationObj, setPaginationObj] = useState(initialPaginationObj)
  const [isFetching, setIsFetching] = useState(true)
  const [promotionData, setPromotionData] = useState<ResponseTypes.PagedResponse<PromotionsTypes.PromotionResponseBL[]>>(initialResponse)
  const [filterSearch, setFilterSearch] = useState(initialSearchParms)
  const [isFormReset, setIsFormReset] = useState(true)
  const [open, setOpen] = useState<boolean>(false)
  const [optedIn, setOptedIn] = useState<boolean>(false)
  const [selPromotion, setSelPromotion] = useState<PromotionsTypes.PromotionResponseBL>()
  const [processing, setProcessing] = useState<boolean>(false)
  const [completed, setCompleted] = useState<boolean>(false)

  //const now = new Date().toISOString()
  const handleSearch = (params: PromotionsTypes.PromotionSearchParam) => {
    const reqParam: PromotionsTypes.PromotionSearchParam = {
      // IsCombinable: false,
      ...params,
      PromotionCategory: 'OPTIN',
      PromotionStatusCode: `${params.PromotionStatusCode == '' ? 'APPROVED' : params.PromotionStatusCode}`,
    }

    setIsFetching(true)
    PromotionService.search(reqParam)
      .then((res) => {
        const data = res.data
        console.log(data.data.length)
        setPromotionData(data)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    handleSearch({...initialSearchParms, ...paginationObj})
    // updatePromotionRequestObj(initialPromotionRequestObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isEmpty = (value: PromotionsTypes.PromotionSearchParam) => {
    if (value.PromotionType || value.StartDateTime || value.EndDateTime || value.PromotionStatusCode) {
      return false
    }
    return true
  }

  const handleFilter = (data: PromotionsFilterState) => {
    // setIsFetching(true)
    const filterParms: PromotionsTypes.PromotionSearchParam = {}
    filterParms.PromotionName = data.promotionName
    filterParms.PromotionStatusCode = data.status
    if (data.dateRange.length >= 1) {
      filterParms.StartDateTime = new Date(data.dateRange[0]).toDateString()
    }
    if (data.dateRange.length >= 2) {
      filterParms.EndDateTime = new Date(data.dateRange[1]).toDateString()
    }
    setFilterSearch(filterParms)
    const hasSearchData = isEmpty(filterParms)
    setIsFormReset(hasSearchData)
    const newPaginationObj = {...paginationObj, PageNumber: initialPaginationObj.PageNumber}
    setPaginationObj(newPaginationObj)

    handleSearch({...initialSearchParms, ...filterParms, ...newPaginationObj})
  }

  const handleResetSearch = () => {
    FilterFormRef.current?.handleReset()
    setFilterSearch(initialSearchParms)
    if (!isFormReset) {
      // paginationObj = initialPaginationObj
      setPaginationObj(initialPaginationObj)

      handleSearch({...initialSearchParms, ...initialPaginationObj})
      setIsFormReset(!isFormReset)
    }
  }

  const handleOnChange = (page: number, pageSize: number) => {
    if (pageSize !== initialPaginationObj.PageCount || page !== initialPaginationObj.PageNumber) {
      setIsFormReset(false)
    } else {
      const hasSearchData = isEmpty(filterSearch)
      setIsFormReset(hasSearchData)
    }

    const newPaginationObj = {...paginationObj, PageCount: pageSize, PageNumber: page}
    setPaginationObj(newPaginationObj)
    handleSearch({...initialSearchParms, ...filterSearch, ...newPaginationObj})
  }

  const handleSelect = (rowIndex: number) => {
    const promotion = promotionData.data[rowIndex]
    console.log(promotion)
    setSelPromotion(promotion)
    if (memberDetail.membershipSegData.find((item) => item.segmentCode === promotion.promotionRuleData.whoData.promotionSegment?.segmentCode)) {
      setOptedIn(true)
    } else {
      setOptedIn(false)
    }
    setOpen(true)
  }

  const handleCancelConfirm = () => {
    navigate(-1)
  }

  const handleOk = () => {
    setOpen(false)
    handleCancelConfirm()
  }

  const handleCancelModal = () => {
    if (completed) {
      setOpen(false)
      setCompleted(false)
      handleClose()
    } else if (optedIn) {
      setOpen(false)
      handleClose()
    }
    setOpen(false)
  }

  const handleOptIn = () => {
    if (selPromotion) {
      setProcessing(true)
      const optedDataReq: MemebershipTypes.optedInMember = {
        membershipCustomerId: memberDetail.membershipCustomerId,
        promotionCode: selPromotion.promotionCode,
      }

      MembershipService.postOptedMemberData(optedDataReq)
        .then((res) => {
          setCompleted(true)
          toast.success('Issued successfully')
        })
        .catch(() => {
          setOpen(false)
        })

        .finally(() => {
          setProcessing(false)
        })
    }
  }

  const renderModalContent = () => {
    if (optedIn) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={1} uppercase>
              Cannot Optin
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Typography.Paragraph size="medium">
              <strong>{memberDetail.membershipFullName}</strong> is already opted in to <strong>{selPromotion?.promotionName}</strong>.
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    if (processing) {
      return (
        <StyledModalContent align="center">
          <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
          <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
        </StyledModalContent>
      )
    }
    if (completed) {
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <IconSuccess />
            &nbsp;
            <Typography.Title level={1} uppercase>
              Member Opted In
            </Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            <Seperator />
          </MarginBottomWrapper>
          <MarginBottomWrapper width="80%">
            <Typography.Paragraph size="medium">
              <strong>{memberDetail.membershipFullName}</strong> has successfully opted in to <strong>{selPromotion?.promotionName}</strong>
            </Typography.Paragraph>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" onClick={handleCancelModal}>
              Close
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return (
      <StyledModalContent align="center">
        <ModalTitle>
          <Typography.Title level={1} uppercase>
            Are You Sure?
          </Typography.Title>
        </ModalTitle>
        <MarginBottomWrapper>
          <Seperator />
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Typography.Paragraph size="medium">
            Are you sure you want to opt <strong>{memberDetail.membershipFullName}</strong> into <strong>{selPromotion?.promotionName}</strong>?
          </Typography.Paragraph>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <ExtraPaddedButton type="primary" key="submit" onClick={handleOptIn}>
            Opt In
          </ExtraPaddedButton>
        </MarginBottomWrapper>
        <MarginBottomWrapper>
          <Button type="link" onClick={handleCancelModal}>
            Back
          </Button>
        </MarginBottomWrapper>
      </StyledModalContent>
    )
  }

  const renderResetSearch = () => {
    return (
      <Button type="link" size="small" onClick={handleResetSearch}>
        Reset Search
      </Button>
    )
  }

  const {pageIndex = 0, count = 0} = promotionData.pageInfo
  const pageSizeOptions = Appconfig.DEFAULT_PAGINATION.PAGINATION_OPTIONS

  return (
    <section>
      <StyledHeader>
        <Typography.Title>Opt in / Search Promotions</Typography.Title>
        <Button onClick={handleClose} type="link">
          Cancel
        </Button>
      </StyledHeader>
      <DataTable>
        <OptInFilter ref={FilterFormRef} isFetching={isFetching} handleFilter={handleFilter} />
        <DataTable.TotalResult resetSearch={renderResetSearch()} noOfResults={count} />
        <SearchResult isFetching={isFetching} handleSelect={handleSelect} promotionsResponse={promotionData} />
        <DataTable.Pagination
          onChange={handleOnChange}
          current={pageIndex}
          pageSizeOptions={pageSizeOptions}
          total={count}
          defaultPageSize={initialPaginationObj.PageCount}
          pageSize={paginationObj.PageCount}
          showSizeChanger
        />
      </DataTable>
      {open && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {renderModalContent()}
        </Modal>
      )}
    </section>
  )
}

export default OptIn
