import IconBrand from 'app/common/icons/IconBrand'
import {
  BrandHeader,
  BrandHeaderContainer,
  BrandLogoSeperator,
  CenteredBlock,
  ClientName,
  HeaderContainer,
  HeaderContent,
  NavButton,
  NavChildrenWrapper,
  NavLink,
  NavChildrenLink,
  NavLinkWrapper,
} from './Navigation.styled'
import {useAuth} from 'app/pages/Auth/AuthContext'
import Colors from 'styles/Colors'
import LoginService from 'app/services/LoginService'
import { parseUserDetailsFromJwt } from 'utils/common'
import IconBrandName from 'app/common/icons/IconBrandName'

const navLinks = [
  {
    to: `/promotions`,
    title: 'Promotion',
  },
  {
    to: `/member`,
    title: 'Members',
  },
  {
    to: `/rewards`,
    title: 'Rewards',
  },
  {
    to: `/segments`,
    title: 'Segmentation',
  },
  {
    to: `/program`,
    title: 'Program Configuration',
    children: [{
      to: '/products',
      title: 'Product Master'
    }, {
      to: '/category',
      title: 'Reward Category'
    }, {
      to: '/tag',
      title: 'Tag Master'
    }, {
      to: '/tiers',
      title: 'Tiers'
    }, {
      to: '/points',
      title: 'Points'
    }, {
      to: '/import',
      title: 'Import Data'
    }, {
      to: '/activitylog',
      title: 'Activity Log'
    }]
  },
  {
    to: `/user-management`,
    title: 'Users',
  },
  // {
  //   to: `/reporting`,
  //   title: 'Reports & analytics',
  // },
  {
    to: `/export-import`,
    title: 'More',
  },
]

const Navigation = ({isBlankNav}: {isBlankNav?: boolean}) => {
  const {logout} = useAuth()
  let clientName = ''
  if (!isBlankNav) {
    const token = LoginService.getSessionToken()
    const userDetail = parseUserDetailsFromJwt(token)
    clientName = userDetail.ClientName
  }

  const handleLogout = () => {
    logout()
  }

  const renderUserMenu = () => {
    return (
      <CenteredBlock>
        <NavButton type="text" color={Colors.WHITE}>Help</NavButton>
        <NavButton type="text" color={Colors.WHITE} onClick={handleLogout}>
          Logout
        </NavButton>
      </CenteredBlock>
    )
  }
  const renderNavLink = () => {
    return navLinks.map((nav, index) => {
      return (
        <NavLinkWrapper key={index}>
          <NavLink to={nav.to.includes('program') ? `${nav.to}/products` : nav.to}>
            {nav.title}
          </NavLink>
          <NavChildrenWrapper className="nav-dropdown">
            {nav.children?.map(item => (
              <NavChildrenLink key={item.title} to={`${nav.to}${item.to}`}>{item.title}</NavChildrenLink>
            ))}
          </NavChildrenWrapper>
        </NavLinkWrapper>
      )
    })
  }

  const renderBrandHeader = () => (
    <BrandHeader>
      <CenteredBlock>
        <IconBrandName />
        {!isBlankNav && (
          <>
            <BrandLogoSeperator />
            <IconBrand />
            <ClientName>{clientName}</ClientName>
          </>
        )}
      </CenteredBlock>
      {!isBlankNav && renderUserMenu()}
    </BrandHeader>
  )

  return (
    <HeaderContainer>
      <BrandHeaderContainer>
        <HeaderContent>{renderBrandHeader()}</HeaderContent>
      </BrandHeaderContainer>
      {!isBlankNav && <HeaderContent>{renderNavLink()}</HeaderContent>}
    </HeaderContainer>
  )
}

export default Navigation
