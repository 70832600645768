import {Modal, Tooltip} from 'antd'
import Button from 'lib/components/Button'
import DataTable from 'lib/components/DataTable'
import {NoRecordFound} from 'lib/components/DataTable/DataTable.styled'
import {useState} from 'react'
import Typography from 'lib/components/Typography'
import LoadingPanel from 'lib/components/LoadingPanel'
import {numberWithCommas} from 'utils/common'

import {MarginBottomWrapper, ModalTitle, StyledModalContent, UrlContainer, UrlLink} from 'app/common/components/Styled/common.styled'

import RewardService from 'app/services/RewardService'
import {toast} from 'react-hot-toast'
import IconEye from 'app/common/icons/IconEye'
// import {useNavigate} from 'react-router-dom'

const dataTableColumns: DataTableTypes.Column[] = [
  {
    title: 'Reward Type',
    id: 'voucherTypeSubCategory',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Reward Name',
    id: 'voucherTypeName',
    textAlign: 'left',
    width: '15%',
  },
  {
    title: 'Reward Id',
    id: 'voucherTypeId',
    textAlign: 'left',
    width: '10%',
  },
  {
    title: 'Reward Description',
    id: 'description',
    textAlign: 'left',
    width: '20%',
  },
  {
    title: 'Cost in Points',
    id: 'point',
    textAlign: 'center',
    width: '8%',
  },
  {
    title: 'Cost in Dollars',
    id: 'amount',
    textAlign: 'center',
    width: '8%',
  },
  {
    title: 'Status',
    id: 'status',
    textAlign: 'center',
    width: '10%',
  },
  {
    title: 'Issue Reward',
    id: 'actions',
    textAlign: 'center',
    width: '14%',
  },
]

interface SearchResultProps {
  isFetching: boolean
  rewardsResponse: ResponseTypes.PagedResponse<RewardsTypes.RewardResponseBL[]>
  memberDetail: MemebershipTypes.MemberDataDetailBL
  handleClose(): void
  memberBalance: number
}

const SearchResults = ({isFetching, rewardsResponse, memberDetail, handleClose, memberBalance}: SearchResultProps) => {
  const [open, setOpen] = useState(false)
  const [isRedeeming, setisRedeeming] = useState(false)
  const [isRedemptionComplete, setIsRedemptionComplete] = useState(false)
  const [selReward, setSelReward] = useState<RewardsTypes.RewardResponseBL>()
  console.log(selReward)
  // const navigate = useNavigate()
  const handleSelect = (index: number) => {
    setSelReward(rewardsResponse.data[index])
    setOpen(true)
  }
  const handleView = (rowIndex: number) => {
    const {data} = rewardsResponse
    const voucherCode = data[rowIndex].voucherTypeCode
    const encodedVoucherCode = btoa(voucherCode)
    const newRoute = `rewards/view/${encodedVoucherCode}`
    //navigate(`/rewards/view/${data[rowIndex].voucherTypeCode}`)

    window.open(`${window.location.origin}/${newRoute}`)
  }
  const renderRowActions = (rowIndex: number) => {
    return (
      <>
        <>
          <Tooltip title="View">
            <Button shape="circle" size="small" ghost icon={<IconEye size={16} />} onClick={() => handleView(rowIndex)} />
          </Tooltip>
        </>

        <Button type="primary" onClick={() => handleSelect(rowIndex)}>
          Select
        </Button>
      </>
    )
  }

  const generateRowData = (): DataTableTypes.Row[] => {
    if (rewardsResponse && rewardsResponse.data) {
      const dataTableRows: DataTableTypes.Row[] = []
      const {data} = rewardsResponse
      data.map((item, index) => {
        const obj: DataTableTypes.Row = {
          voucherTypeSubCategory: item.voucherTypeSubCategory,
          voucherTypeName: item.voucherTypeName,
          voucherTypeId: item.voucherTypeCode,
          description: item.voucherTypeDisclaimer,
          point: numberWithCommas(item.salePoint !== undefined && item.salePoint === 0 ? item.point : item.salePoint ?? item.point),
          amount: item.amount,
          status: item.voucherStatus,
          actions: renderRowActions(index),
        }
        dataTableRows.push(obj)
        return null
      })

      return dataTableRows
    }
    return []
  }

  const handleOk = () => {
    if (!selReward) {
      return
    }
    setisRedeeming(true)
    const issueRewardReq: RewardsTypes.IssueRewardBL = {
      membershipCode: memberDetail.membershipCustomerId,
      voucherTypeCode: selReward.voucherTypeCode,
      offerType: 'POINTREWARD',
      source: 'ADMIN',
    }

    RewardService.issueReward(issueRewardReq)
      .then((res) => {
        selReward.voucherTokenCode = res.data.data[0].voucherTokenCode
        setIsRedemptionComplete(true)
        toast.success('Issued successfully')
      })
      .finally(() => {
        setisRedeeming(false)
      })
  }

  const handleGoBack = () => {
    handleClose()
  }

  const handleCancelModal = () => {
    if (isRedemptionComplete) {
      handleClose()
    } else {
      setOpen(false)
      setIsRedemptionComplete(false)
    }
  }

  const renderNoRecordFound = () => {
    return <NoRecordFound>No search results were found for your query. Maybe give it another try ?</NoRecordFound>
  }

  const getModalContent = () => {
    if (selReward) {
      if (isRedeeming) {
        return (
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '32px'}}>
            <MarginBottomWrapper>{<LoadingPanel />}</MarginBottomWrapper>
            <Typography.Title level={2}>Please wait while we process your request..</Typography.Title>
          </div>
        )
      }
      if (isRedemptionComplete) {
        return (
          <StyledModalContent align="center">
            <ModalTitle>
              <Typography.Title level={1}>Reward Redeemed</Typography.Title>
            </ModalTitle>
            <MarginBottomWrapper>
              <Typography.Paragraph size="medium">
                {selReward.voucherTypeName} has been issued and{' '}
                {`${
                  selReward.salePoint !== undefined && selReward.salePoint === 0 ? selReward.point : selReward.salePoint ?? selReward.point
                } Points`}{' '}
                have been deducted from{' '}
                {`${
                  memberDetail.membershipFirstName === null || memberDetail.membershipFirstName === '' ? 'Member' : memberDetail.membershipFirstName
                }'s`}{' '}
                account.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Typography.Title level={3}>Confirmation Number: {selReward.voucherTokenCode}</Typography.Title>
              <Typography.Paragraph size="medium">
                A record of this transaction will also be available in the member account activity feed as well as rewards table.
              </Typography.Paragraph>
            </MarginBottomWrapper>
            <MarginBottomWrapper>
              <Button type="primary" key="submit" onClick={handleGoBack}>
                Close
              </Button>
            </MarginBottomWrapper>
          </StyledModalContent>
        )
      }

      const isBalanceCheck = () => {
        if (selReward && selReward.saleFrom != null && selReward.salePoint) {
          return memberBalance - selReward.salePoint >= 0
        }
        return memberBalance - selReward.point >= 0;
      }
      return (
        <StyledModalContent align="center">
          <ModalTitle>
            <Typography.Title level={1}>Are you sure?</Typography.Title>
          </ModalTitle>
          <MarginBottomWrapper>
            {isBalanceCheck() ? (
              <Typography.Paragraph size="medium">
                Are you sure you want to issue {selReward.voucherTypeCode} {selReward.voucherTypeName} to {memberDetail.membershipCustomerId}{' '}
                {memberDetail.membershipFirstName} {memberDetail.membershipLastName}. This reward is for{' '}
                {`${
                  selReward.salePoint !== undefined && selReward.salePoint === 0 ? selReward.point : selReward.salePoint ?? selReward.point
                } points.`}{' '}
                Please check reward details here:
                <UrlContainer>
                  <UrlLink
                    href={`https://${selReward.url?.slice(0, 8) === 'https://' ? selReward.url?.slice(8, selReward.url.length) : selReward.url}`}
                    target="_blank"
                  >
                    {' '}
                    {selReward.url}
                  </UrlLink>
                </UrlContainer>
                <br />
                {`${
                  memberDetail.membershipFirstName === null || memberDetail.membershipFirstName === '' ? 'Member' : memberDetail.membershipFirstName
                }'s `}{' '}
                current balance is {memberBalance} and balance post rewards issue will be{' '}
                {selReward?.salePoint !== undefined && selReward.salePoint === 0
                  ? memberBalance - selReward.point
                  : memberBalance - (selReward?.salePoint ?? selReward.point)}
                .
              </Typography.Paragraph>
            ) : (
              <Typography.Paragraph size="medium">Insufficient balance to complete redemption!!</Typography.Paragraph>
            )}
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button type="primary" key="submit" onClick={handleOk} disabled={!isBalanceCheck()}>
              Continue with Redemption
            </Button>
          </MarginBottomWrapper>
          <MarginBottomWrapper>
            <Button key="back" type="link" onClick={handleCancelModal}>
              Back
            </Button>
          </MarginBottomWrapper>
        </StyledModalContent>
      )
    }
    return null
  }

  return (
    <>
      <DataTable.Header columns={dataTableColumns} />
      <DataTable.Rows noRecordFoundComponent={renderNoRecordFound()} loading={isFetching} columns={dataTableColumns} rows={generateRowData()} />
      {open && selReward && (
        <Modal open={open} onOk={handleOk} onCancel={handleCancelModal} footer={null}>
          {getModalContent()}
        </Modal>
      )}
    </>
  )
}

export default SearchResults
